// import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";

// types and state
import { setCourse, updateCourse, setLoading } from "../../store/slices/course";

export const get_courses = async (dispatch: Function) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/get-college-major");

    dispatch(setCourse(response.data));
  } catch (error) {
    dispatch(setCourse([]));
  }
  dispatch(setLoading());
};

export const create_course = async (
  dispatch: Function,
  data: { name: string }
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/create-college-major", data);

    dispatch(updateCourse(response.data?.data));
  } catch (error) {
    // dispatch(setRole([]));
  }
  dispatch(setLoading());
};
