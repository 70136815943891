import React from "react";
import useChangeAvatar from "../../../hooks/useChangeAvatar";
import { useAppSelector } from "../../../../store/hooks";
import SlimSelect from "../../../../components/elements/forms/SlimSelect";

// constants
import avatar from "../../../../assets/images/temporary/user.png";

const UpdateAvatar = () => {
  const user = useAppSelector(({ auth }) => auth.user);
  const { handleAvatar, upload_loading } = useChangeAvatar();

  return (
    <div className="flex items-center align-middle w-full my-3 h-[100px] md:h-[150px]">
      <img
        src={user?.profile?.avatar || avatar}
        alt={user?.profile?.first_name + " Avatar"}
        className="rounded-full object-cover w-[100px] h-[100px] md:w-[100px] md:h-[100px] border border-[#ddd]"
      />
      <div className=" flex items-center pl-4">
        <SlimSelect
          name="name"
          loading={upload_loading}
          placeholder="Upload a new Avatar"
          onSelect={handleAvatar}
        />
      </div>
    </div>
  );
};

export default UpdateAvatar;
