import { configureStore } from "@reduxjs/toolkit";

// environment
import env from "../config/env";

// store
import auth from "./slices/auth";
import jobs from "./slices/jobs";
import misc from "./slices/misc";
import loading from "./slices/loading";
import message from "./slices/message";
import applicants from "./slices/applicants";
import experience from "./slices/experience";
import education from "./slices/education";
import role from "./slices/role";
import company from "./slices/company";
import college from "./slices/college";
import course from "./slices/course";
import degree from "./slices/degree";
import certificate from "./slices/certificate";

export const store = configureStore({
  reducer: {
    auth,
    loading,
    jobs,
    misc,
    message,
    applicants,
    experience,
    education,
    role,
    company,
    college,
    course,
    degree,
    certificate,
  },
  devTools: env.enviroment === "production" ? false : true,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
