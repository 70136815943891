import React from "react";

// constants
import { BiCheckbox, BiCheckboxChecked } from "react-icons/bi";
import { ErrorWarning } from "../../../store/types/misc";

export type ICheckBox2 = {
  name: string;
  key?: string | number;
  id: string | number;
  onChange: (value: string | number) => void;
  checked?: boolean;
  status: ErrorWarning;
};

const CheckBox2: React.FC<ICheckBox2> = ({ id, checked, onChange, status }) => {
  const onClick = () => {
    onChange?.(id);
  };
  const borderColor = status ? "red" : "#243677";

  return (
    <div
      className={`cursor-pointer flex items-center font-abel mr-2  ${
        checked ? "bg-[#ffffff]" : "bg-white"
      }`}
      onClick={onClick}
    >
      {checked ? (
        <BiCheckboxChecked color={borderColor} size={20} />
      ) : (
        <BiCheckbox color={borderColor} size={20} />
      )}
    </div>
  );
};

export default CheckBox2;
