import React from "react";

// constants
import { Spin } from "antd";
import { BiCheckCircle } from "react-icons/bi";
import upload from "../../../assets/images/icon_images/upload.png";
import SubmitButton from "./SubmitButton";

type IUpload = {
  note?: string;
  name: string;
  value?: string;
  error?: string;
  className?: string;
  onUpload: (file: any) => void;
  message?: string;
  finished?: boolean;
  loading?: boolean;
  uploadIcon?: any;
};

const Upload: React.FC<IUpload> = ({
  name,
  uploadIcon,
  value,
  error,
  finished,
  loading,
  message,
  className,
  note,
  onUpload,
}) => {
  const onChangeFileHandler = (event: any): File | void => {
    const { files } = event.target;
    if (files.length) onUpload(files);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [show, showSet] = React.useState<boolean>(false);

  return (
    <div className={className}>
      <h6 className="mb-2 md:text-[16px] font-[600]">
        {message || "Attach the file below (pdf only)"} {note}
      </h6>
      <div
        className={`cursor-pointer border-2 w-100 ${
          error ? "border-[#ff0707]" : "border-[#4E4E4E]"
        } border-dashed rounded-xl flex justify-center items-center py-5 flex-col`}
        onClick={() => document.getElementById(name)?.click()}
      >
        <div className="py-2">
          <img
            src={uploadIcon || upload}
            alt="Upload Data Icon"
            className="w-10 h-10"
          />
        </div>
        <div className="text-center px-3">
          <h6 className="text-[12px] font-[300] md:text-[14px]">
            Drag files here to upload
          </h6>
          <h6 className="text-[12px] md:text-[14px]">
            Alternatively, you can select file by
          </h6>

          <div className="flex item-center md:my-2 justify-center">
            <button className="text-[#243677] underline font-bold">
              Clicking here
            </button>
            {finished && <BiCheckCircle size={30} color="#319759" />}
            {loading && <Spin />}
          </div>
        </div>
      </div>
      <input
        type="file"
        name={name}
        value={value}
        id={name}
        onChange={onChangeFileHandler}
        hidden
      />
      <SubmitButton
        title="OK"
        className="w-[100%] md:w-[100%] mt-4"
        onSubmitHandler={() => showSet(true)}
      />
    </div>
  );
};

export default Upload;
