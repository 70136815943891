import React from 'react';

// component
import { Modal, notification } from 'antd';
import Upload from '../../../components/elements/forms/Upload';
import { Skeleton } from 'antd';

// state
import {get_profile} from '../../../services/api/auth'
import axiosUpload from '../../../utility/axiosUpload';
import axiosInstance from '../../../utility/axiosInstance';
import { useAppDispatch } from '../../../store/hooks';
import { isAxiosError } from 'axios';


type IUploadAvatar = {
  visible: boolean;
  cv: string
  onHandleOk: () => void;
  onCancel: () => void;
}

const UploadCV: React.FC<IUploadAvatar> = ({cv, visible, onHandleOk, onCancel}) => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = React.useState<boolean>(false);

  const onUpload = async (file: any) => {
    setLoading(true)

    try {
      const body = new FormData();
      body.append('name', file?.[0]);

      const config = {
        onUploadProgress: (progressEvent: any) => {
          const {progress} = progressEvent;
          Math.ceil((progress / 1) * 100);
        },
        transformRequest: (datum: any) => datum 
      }

      const response = await axiosUpload.post('/cv-upload', body, config)
      if (response.data) {
        const update = await axiosInstance.post('/update-cv', {name: response.data});
        get_profile(dispatch)
        notification.success({
          message: "Success",
          description: update.data?.message
        });
      }
    } catch (err) {
      if (isAxiosError(err)) {
        notification.warning({
          message: 'Upload Failed',
          description: err?.response?.data?.message
        })
      }
    }
    setLoading(false)
  }

  return (
    <Modal
      title="Curriculum Vitae"
      centered
      open={visible}
      confirmLoading={loading}
      onCancel={onCancel}
      bodyStyle={{backgroundColor: 'rgba(1, 9, 37, 0.83)'}}
      width={1000}
      footer={null}
    >
      <div className='flex w-full bg-[#fff] h-[60vh]'>
        <div className='hidden md:w-8/12 md:block'>
          <iframe id="fred" title="My Curriculum Vitae" src={cv} height="100%" width="100%" />
        </div>
        <div className='w-full md:w-4/12 flex flex-col items-center justify-center md:border-l md:border-[grey]'>
          <Upload name="name" onUpload={onUpload} />
          
          {loading &&
            <div className="w-full my-3 px-3 py-2 md:my-5 md:px-5 md:py-4">
              <Skeleton.Button active={true} size="large" shape="default" block={true} />
            </div> 
          }
        </div>
      </div>
    </Modal>
  );
};

export default UploadCV;