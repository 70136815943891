import React from 'react'

// components
import TextInput from '../../../../components/elements/forms/TextInput'
import Button from '../../../../components/elements/forms/Button';

// constants and service
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaSkype, FaWhatsapp, FaGlobe, FaTwitter } from "react-icons/fa";
import { validateURL } from '../../../../services/formatter/validate';

// types and state
import { ISocial, ISocialError } from '../../../../store/types/profile';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { update_social_me } from '../../../../services/api/profile';

const UpdateSocialMediaLinks = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(({ auth }) => auth.user);
  const [detail, detailSet] = React.useState<ISocial>({
    facebook: '',
    instagram: '',
    linked_in: '',
    skype: '',
    twitter: '',
    website: '',
    whatsapp: ''
  })
  const [data, dataSet] = React.useState<ISocial>({
    facebook: '',
    instagram: '',
    linked_in: '',
    skype: '',
    twitter: '',
    website: '',
    whatsapp: ''
  });

  const [error, errorSet] = React.useState<ISocialError>({
    facebook: '',
    instagram: '',
    linked_in: '',
    skype: '',
    twitter: '',
    website: '',
    // whatsapp: ''
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    if (validateURL(data[name as keyof typeof data])) {
      errorSet(prev => ({ ...prev, [name]: '' }))
    } else {
      errorSet(prev => ({ ...prev, [name]: 'warning' }))
    }
    dataSet(prev => ({ ...prev, [name]: value }))
  }

  const onLoad = React.useCallback(() => {
    if (user) {
      dataSet(prev => ({
        ...prev,
        facebook: user?.profile.address?.about?.social?.facebook || "",
        instagram: user?.profile.address?.about?.social?.instagram || "",
        linked_in: user?.profile.address?.about?.social?.linked_in || "",
        skype: user?.profile.address?.about?.social?.skype || "",
        twitter: user?.profile.address?.about?.social?.twitter || "",
        website: user?.profile.address?.about?.social?.website || "",
        whatsapp: user?.profile.address?.about?.social?.whats_app || ""
      }))
      detailSet(prev => ({
        ...prev,
        facebook: user?.profile.address?.about?.social?.facebook || "",
        instagram: user?.profile.address?.about?.social?.instagram || "",
        linked_in: user?.profile.address?.about?.social?.linked_in || "",
        skype: user?.profile.address?.about?.social?.skype || "",
        twitter: user?.profile.address?.about?.social?.twitter || "",
        website: user?.profile.address?.about?.social?.website || "",
        whatsapp: user?.profile.address?.about?.social?.whats_app || ""
      }))
    }
  }, [user]);

  React.useEffect(() => {
    onLoad()
  }, [onLoad])
  return (
    <div className='w-80%'>
      <TextInput name="facebook" container='mb-2' status={error.facebook} prefix={<FaFacebookF size={20} />} value={data.facebook} placeholder='Facebook' onChange={onChange} />
      <TextInput name="instagram" container='my-2' status={error.instagram} prefix={<FaInstagram size={20} />} value={data.instagram} placeholder='Instagram' onChange={onChange} />
      <TextInput name="linked_in" container='my-2' status={error.linked_in} prefix={<FaLinkedinIn size={20} />} value={data.linked_in} placeholder='LinkedIn' onChange={onChange} />
      <TextInput name="twitter" container='my-2' status={error.twitter} prefix={<FaTwitter size={20} />} value={data.twitter} placeholder='Twitter' onChange={onChange} />
      <TextInput name="website" container='my-2' status={error.website} prefix={<FaGlobe size={20} />} value={data.website} placeholder='Website' onChange={onChange} />
      <TextInput name="whatsapp" container='my-2' prefix={<FaWhatsapp size={20} />} value={data.whatsapp} placeholder='WhatsApp Number' onChange={onChange} />
      <TextInput name="skype" container='mt-2 mb-3' status={error.skype} prefix={<FaSkype size={20} />} value={data.skype} placeholder='Skype' onChange={onChange} />
      {(detail.facebook !== data.facebook || detail.instagram !== data.instagram || detail.linked_in !== data.linked_in || detail.skype !== data.skype || detail.twitter !== data.twitter || detail.website !== data.website || detail.whatsapp !== data.whatsapp) &&
        (<div className='mb-3 text-right'>
          <Button title='Submit' type="button" onSubmit={() => update_social_me(dispatch, data)} />
        </div>)
      }
    </div>
  )
}

export default UpdateSocialMediaLinks
