import React from "react";
// import useEducation from "../hooks/useEducation";

// components
import Button from "../../../components/elements/forms/Button";
import TextInput from "../../../components/elements/forms/TextInput";

// state and types
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ErrorWarning } from "../../../store/types/misc";
import {
  create_cert,
  delete_cert,
  get_certification,
  update_cert,
} from "../../../services/api/certificate";
import { CiEdit } from "react-icons/ci";
import { IoMdTrash } from "react-icons/io";

type IDataType = {
  id: string;
  name: string;
};

const Certification: React.FC = () => {
  const dispatch = useAppDispatch();
  const certificates = useAppSelector(
    ({ certificate }) => certificate.certificates
  );
  const [show, showSet] = React.useState<boolean>(false);
  const [data, dataSet] = React.useState<IDataType>({
    id: "",
    name: "",
  });
  const [error, errorSet] = React.useState<ErrorWarning>("");

  const onLoad = React.useCallback(() => {
    get_certification(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="py-0 mb-3 font-abel">
      <div className="flex flex-wrap w-full mb-2">
        {certificates?.map((item) => (
          <div
            key={item.id}
            className="capitalize mb-[2px] mr-2 px-2 py-1 bg-[#243677] text-[white] flex items-center text-[15px]"
          >
            {item.name}

            <CiEdit
              size={25}
              className="ml-[4px] cursor-pointer"
              onClick={() => {
                dataSet({ id: item.id?.toString(), name: item.name });
                showSet(true);
              }}
            />
            <IoMdTrash
              size={25}
              className="ml-[4px] cursor-pointer"
              onClick={() => {
                delete_cert(dispatch, item.id?.toString());
                dataSet({ id: "", name: "" });
                errorSet("");
              }}
            />
          </div>
        ))}
      </div>

      {show && (
        <div className="flex w-full justify-center items-center">
          <div className="w-[70%]">
            <TextInput
              name="name"
              onChange={(e) => {
                const { name, value } = e.target;
                dataSet((prev) => ({ ...prev, [name]: value }));
                errorSet("");
              }}
              placeholder="Certification"
              value={data.name}
              status={error}
            />
          </div>

          <div className="w-[20%] px-2">
            <div className="flex">
              <div>
                <Button
                  title="Save"
                  className="mr-5 text-[white] bg-[#243677] border border-[#243677]"
                  type="button"
                  onSubmit={() => {
                    if (data.id && data.name) {
                      update_cert(dispatch, data);
                    } else if (data.name && !data.id) {
                      create_cert(dispatch, data);
                    }
                    dataSet({ id: "", name: "" });
                    showSet(false);
                    errorSet("");
                  }}
                />
              </div>
              <div>
                <Button
                  title="Cancel"
                  type="button"
                  className=""
                  onSubmit={() => {
                    showSet(false);
                    dataSet({ id: "", name: "" });
                    errorSet("");
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="text-[#243677]">
        {!show && (
          <button
            type="button"
            className="border-none "
            onClick={() => showSet(true)}
          >
            + Add education
          </button>
        )}
      </div>
    </div>
  );
};

export default Certification;
