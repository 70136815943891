import React from "react";
import useRegister from "../hooks/useRegister";
import SubmitButton from "../../../components/elements/forms/SubmitButton";

// components
import TextInput from "../../../components/elements/forms/TextInput";

// constants and images
// import register from '../../../assets/images/register/organization.png';

// theme
import Landing from "../app";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";
import EmailNotSet from "../../../components/ui/typography/EmailNotSet";
import SweetAlert from "../../../components/ui/sweet_alert";
import ConsentForm from "../misc/ConsentForm";
import CheckBox2 from "../../../components/elements/forms/CheckBox2";

const Register = () => {
  const navigate = useNavigate();
  const loading = useAppSelector(({ loading }) => loading.loading);
  const message = useAppSelector(({ message }) => message.message);
  const {
    user,
    emailExist,
    userErr,
    onChangeHandlerUser,
    onSubmitUser,
    onCheckEmail,
    onCheck,
  } = useRegister();
  const [show, showSet] = React.useState<boolean>(false);
  console.log(userErr);

  return (
    <Landing className="w-full">
      <div className=" md:py-3 md:flex w-full bg-[#EFF2FE] justify-center items-center">
        <div className="bg-[white] px-4 py-10 items-center md:mx-5 md:min-w-[450px] border-none rounded-lg md:px-10 md:pt-4 md:pb-1 md:my-12">
          <div className="flex my-2">
            <div className="md:w-[100%] w-full flex justify-center items-center px-2">
              <div className=" w-full">
                <div className=" w-full my-4">
                  <h3 className="text-[23px] font-bold text-left md:text-[30px]">
                    Sign Up
                  </h3>
                  {/* <span className='text-lg font-[700] text-[#aaaaaa]'>Thank you for choosing 25th & Staffing. To get started, kindly sign-up</span> */}
                </div>

                <div className="w-full mb-3">
                  <TextInput
                    status={userErr.first_name}
                    placeholder="First Name"
                    name="first_name"
                    value={user.first_name}
                    onChange={onChangeHandlerUser}
                  />
                </div>

                <div className="w-full mb-3">
                  <TextInput
                    status={userErr.last_name}
                    placeholder="Last Name"
                    name="last_name"
                    value={user.last_name}
                    onChange={onChangeHandlerUser}
                  />
                </div>

                <div className="w-full my-3">
                  <TextInput
                    status={userErr.email}
                    placeholder="Email address"
                    name="email"
                    value={user.email}
                    onBlur={onCheckEmail}
                    onChange={onChangeHandlerUser}
                  />
                  {emailExist && <EmailNotSet />}
                </div>

                <div className="w-full my-3">
                  <TextInput
                    type="password"
                    status={userErr.password}
                    placeholder="Password"
                    name="password"
                    value={user.password}
                    onChange={onChangeHandlerUser}
                  />
                </div>
                <div className="w-full my-3">
                  <TextInput
                    type="password"
                    status={userErr.password_confirmation}
                    placeholder="Confirm password"
                    name="password_confirmation"
                    value={user.password_confirmation}
                    onChange={onChangeHandlerUser}
                  />
                </div>

                <div className="w-full flex justify-start font-abel">
                  <CheckBox2
                    name="accept"
                    id="accept"
                    checked={user.consent}
                    onChange={onCheck}
                    status={userErr.consent}
                  />
                  <span
                    onClick={() => showSet(true)}
                    className="text-[16px] text-[#011048] pr-2 font-abel cursor-pointer"
                  >
                    In order to proceed,{" "}
                    <span className="underline">click here</span> to view the
                    consent form
                  </span>
                </div>

                <div className="w-full my-2 text-center mt-5">
                  <SubmitButton
                    title="Register"
                    loading={loading}
                    onSubmitHandler={onSubmitUser}
                    className="md:w-[100%]"
                  />
                </div>

                <div className="mt-5 mb-5">
                  <span>
                    Already have an account?
                    <Link to="/login" className="text-[#243677]">
                      {" "}
                      Sign In
                    </Link>
                  </span>
                </div>

                <ConsentForm
                  visible={show}
                  onHandleOk={() => showSet(false)}
                  onCancel={() => showSet(false)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <SweetAlert
        visible={message ? true : false}
        button_name="Login"
        onSubmit={() => navigate("/login")}
      />
    </Landing>
  );
};

export default Register;
