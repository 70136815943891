import React from "react";

// components
import TextInput from "../../../../components/elements/forms/TextInput";
import Button from "../../../../components/elements/forms/Button";

// state and services
import { IUpdatePhoneNumber } from "../../../../store/types/profile";
import { update_phone_number } from "../../../../services/api/profile";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";

const UpdatePhoneNumber = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(({ auth }) => auth.user);
  const [show, showSet] = React.useState<boolean>(false);
  const [data, dataSet] = React.useState<IUpdatePhoneNumber>({
    phone: "",
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dataSet((prev) => ({ ...prev, [name]: value }));
    showSet(true);
  };

  const onLoad = React.useCallback(() => {
    if (user) {
      dataSet((prev) => ({
        ...prev,
        phone: user?.profile?.phone || "",
      }));
    }
  }, [user]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="w-full">
      <div className="flex w-full mt-3">
        <TextInput
          value={data.phone}
          container="w-[100%]"
          name="phone"
          placeholder="Phone Number"
          onChange={onChange}
        />
      </div>

      {show && (
        <div className="w-full text-right my-2">
          <Button
            title="Save"
            type="button"
            onSubmit={() => {
              update_phone_number(dispatch, data);
              showSet(false);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default UpdatePhoneNumber;
