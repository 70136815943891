// import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";

// types and state
import {
  setCompany,
  updateCompany,
  setLoading,
} from "../../store/slices/company";

export const get_companies = async (dispatch: Function) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/get-companies");

    dispatch(setCompany(response.data));
  } catch (error) {
    dispatch(setCompany([]));
  }
  dispatch(setLoading());
};

export const create_company = async (
  dispatch: Function,
  data: { name: string }
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/create-company", data);

    dispatch(updateCompany(response.data?.data));
  } catch (error) {
    // dispatch(setRole([]));
  }
  dispatch(setLoading());
};
