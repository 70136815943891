import React from "react";
// import useUpdateEducation from "../hooks/useUpdateEducation";

// components
// import TextInput from '../../../../components/elements/forms/TextInput';
import DateInput from "../../../components/elements/forms/DateInput";
import CheckBox from "../../../components/elements/forms/CheckBox";
import Button from "../../../components/elements/forms/Button";
import CustomAutoComplete from "../../../components/elements/forms/CustomAutoComplete";

// constants
// import { get_month_and_year } from "../../../services/formatter/date";
// import { FcGraduationCap } from "react-icons/fc";

// state and types
import {
  IEducation,
  IEducationData,
  IEducationError,
} from "../../../store/types/auth";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { IDataType } from "../../../store/types/misc";
import { create_degree } from "../../../services/api/degree";
import { create_course } from "../../../services/api/course";
import { create_college } from "../../../services/api/college";
import {
  create_education,
  update_education,
} from "../../../services/api/education";
import { Spin } from "antd";

type IFillSet = {
  college: string;
  degree: string;
  major: string;
};

type IUpdateEducate = {
  education: IEducation | null;
  onDone: () => void;
};

const UpdateEducation: React.FC<IUpdateEducate> = ({ education, onDone }) => {
  const dispatch = useAppDispatch();

  const loading = useAppSelector(({ education }) => education.loading);
  const colleges = useAppSelector(({ college }) => college.colleges);
  const college_loading = useAppSelector(({ college }) => college.loading);
  const college_majors = useAppSelector(({ course }) => course.courses);
  const course_loading = useAppSelector(({ course }) => course.loading);
  const degrees = useAppSelector(({ degree }) => degree.degrees);
  const degree_loading = useAppSelector(({ degree }) => degree.loading);

  const [fill, fillSet] = React.useState<IFillSet>({
    college: "",
    degree: "",
    major: "",
  });
  const [data, dataSet] = React.useState<IEducationData>({
    college_id: "",
    graduation: "",
    degree_id: "",
    major_id: "",
    highest: false,
    id: "",
    current: false,
  });
  const [error, errorSet] = React.useState<IEducationError>({
    id: "",
    college_id: "",
    graduation: "",
    degree_id: "",
    major: "",
  });

  const onValidate = () => {
    let validate: boolean = false;
    if (!data.college_id) {
      validate = true;
      errorSet((prev) => ({ ...prev, college_id: "warning" }));
    }
    if (!data.degree_id) {
      validate = true;
      errorSet((prev) => ({ ...prev, degree_id: "warning" }));
    }
    if (!data.graduation && !data.current) {
      validate = true;
      errorSet((prev) => ({ ...prev, graduation: "warning" }));
    }
    if (!data.major_id) {
      validate = true;
      errorSet((prev) => ({ ...prev, major: "warning" }));
    }
    if (validate) {
      return false;
    }
    return true;
  };

  const onSubmit = async () => {
    const validate = onValidate();
    console.log("Validate");
    if (validate && data.id) {
      const response = await update_education(dispatch, data);
      if (response) onClear();
    } else if (validate && !data.id) {
      const response = await create_education(dispatch, data);
      if (response) onClear();
    } else {
    }
  };

  const onClear = () => {
    dataSet({
      college_id: "",
      graduation: "",
      degree_id: "",
      major_id: "",
      id: "",
      highest: false,
      current: false,
    });
    fillSet({
      college: "",
      degree: "",
      major: "",
    });
    errorSet({
      id: "",
      college_id: "",
      graduation: "",
      degree_id: "",
      major: "",
    });
    onDone();
  };

  const onLoad = React.useCallback(() => {
    if (education && education.id) {
      dataSet({
        college_id: education?.college?.id?.toString(),
        graduation: education?.graduation || "",
        degree_id: education?.degree?.id?.toString(),
        major_id: education?.major?.id?.toString(),
        id: education.id?.toString(),
        highest: education?.highest ? true : false,
        current: education.graduation ? false : true,
      });
      fillSet((prev) => ({
        ...prev,
        college: education?.college?.name,
        degree: education?.degree?.name,
        major: education?.major?.name,
      }));
    }
  }, [education]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="capitalize relative bg-[#F5F5F5] px-5 py-3 rounded-md shadow-md my-2">
      <div className="py-0">
        <CustomAutoComplete
          items={colleges}
          container="my-2"
          placeholder="Enter College | University"
          value={fill.college}
          status={error.college_id}
          loading={college_loading}
          onCreate={(res) => create_college(dispatch, { name: res })}
          onChange={(e) =>
            fillSet((prev) => ({ ...prev, college: e.target.value }))
          }
          onSelect={(res: IDataType) => {
            fillSet((prev) => ({ ...prev, college: res.name }));
            errorSet((prev) => ({ ...prev, college_id: "" }));
            dataSet((prev) => ({ ...prev, college_id: res.id.toString() }));
          }}
        />

        <CustomAutoComplete
          items={college_majors}
          container="my-2"
          placeholder="Major/Field of study"
          value={fill.major}
          loading={course_loading}
          onCreate={(res) => create_course(dispatch, { name: res })}
          status={error.college_id}
          onChange={(e) =>
            fillSet((prev) => ({ ...prev, major: e.target.value }))
          }
          onSelect={(res: IDataType) => {
            fillSet((prev) => ({ ...prev, major: res.name }));
            errorSet((prev) => ({ ...prev, major: "" }));
            dataSet((prev) => ({ ...prev, major_id: res.id?.toString() }));
          }}
        />

        <CustomAutoComplete
          items={degrees}
          container="my-2"
          placeholder="Degree & Major"
          value={fill.degree}
          status={error.college_id}
          loading={degree_loading}
          onCreate={(res) => create_degree(dispatch, { name: res })}
          onChange={(e) =>
            fillSet((prev) => ({ ...prev, degree: e.target.value }))
          }
          onSelect={(res: IDataType) => {
            fillSet((prev) => ({ ...prev, degree: res.name }));
            errorSet((prev) => ({ ...prev, degree_id: "" }));
            dataSet((prev) => ({ ...prev, degree_id: res.id?.toString() }));
          }}
        />

        <DateInput
          placeholder="End Date"
          container="w-full mb-2"
          value={data.graduation || new Date()}
          status={error.graduation}
          disabled={data.current || false}
          onSelect={(e) => {
            dataSet((prev) => ({ ...prev, end_date: e }));
            errorSet((prev) => ({ ...prev, end_date: "" }));
          }}
        />

        <div className="mb-2">
          <CheckBox
            name="currently"
            id="currently"
            checked={data.current}
            onChange={() => {
              dataSet((prev) => ({ ...prev, current: !data.current }));
              if (!data.current) {
                dataSet((prev) => ({ ...prev, graduation: "" }));
                errorSet((prev) => ({ ...prev, graduation: "" }));
              }
            }}
            title="Still running"
          />
        </div>

        <CheckBox
          name="highest"
          id="highest"
          checked={data.highest}
          onChange={() =>
            dataSet((prev) => ({ ...prev, highest: !data.highest }))
          }
          title="Highest Level of Education"
        />

        <div className="my-2">
          {loading ? (
            <Spin className="mx-5" />
          ) : (
            <Button
              title="Save"
              className="mr-5 text-[white] bg-[#243677] border border-[#243677]"
              type="button"
              onSubmit={onSubmit}
            />
          )}
          <Button title="Cancel" type="button" onSubmit={onDone} />
        </div>
      </div>
    </div>
  );
};

export default UpdateEducation;
