import React, { useState } from "react";
import { Steps, Modal } from "antd";

// component
import ConsentContent from '../../../components/elements/forms/Consent';
import SubmitButton from '../../../components/elements/forms/SubmitButton';
import ConsentContent2 from "../../../components/elements/forms/Consent2";


type Consent = {
    visible: boolean;
    onHandleOk: () => void;
    onCancel: () => void;
}

const steps = [
    {
        title: 'Consent 1',
        content: <ConsentContent />,
    },
    {
        title: 'Consent 2',
        content: <ConsentContent2 />,
    },
]

const ConsentForm: React.FC<Consent> = ({ visible, onCancel }) => {
    const [current, setCurrent] = useState(0);
  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

    return (
        <Modal
            title=""
            centered
            open={visible}
            onCancel={onCancel}
            bodyStyle={{ backgroundColor: 'rgba(1, 9, 37, 0.83)' }}
            width={1000}
            footer={null}
        >
            <div className='bg-[#fff] h-full font-abel px-2'>
                <div className='w-full text-center'>
                    <Steps
                        progressDot
                        current={current}
                        items={items}
                        className="pt-4 pr-5"
                    />
                    <div className="px-5 pt-3">{steps[current].content}</div>
                    {/* <ConsentContent /> */}
                    <div className="flex justify-center mt-8 w-100">
                        {current > 0 && (
                        <div className="w-[150px]">
                            <SubmitButton title="Back" onSubmitHandler={() => prev()} />
                        </div>
                        )}
                        {current === steps.length - 1 && (
                        <div className="w-[150px] ml-3">
                            <SubmitButton
                            title="Accept"
                            onSubmitHandler={onCancel}
                            />
                        </div>
                        )}

                        {current < steps.length - 1 && (
                        <div className="w-[150px] ml-3">
                            <SubmitButton title="Next" onSubmitHandler={() => next()} />
                        </div>
                        )}
                    </div>
                    {/* <div className='w-full text-center mb-2 mt-4'>
                        <SubmitButton title="Accept" onSubmitHandler={onCancel} className="md:w-[200px]" />
                    </div> */}
                </div>
            </div>
        </Modal>
    );
};

export default ConsentForm;