import React from "react";

// components
import UpdateWorkExperience from "./UpdateWorkExperience";

// state and types
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { get_companies } from "../../../services/api/company";
import { get_roles } from "../../../services/api/role";
import { IWorkExperience } from "../../../store/types/auth";
import { get_month_and_year } from "../../../services/formatter/date";

const WorkExperience = () => {
  const dispatch = useAppDispatch();
  const experiences = useAppSelector(
    ({ experience }) => experience.experiences
  );

  const [show, showSet] = React.useState<boolean>(false);
  const [exp, expSet] = React.useState<IWorkExperience | null>(null);

  const onLoad = React.useCallback(() => {
    get_roles(dispatch);
    get_companies(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="py-0 mb-3 font-abel">
      {experiences?.map((item, index) => (
        <div
          key={item.id}
          className={`capitalize bg-[#F5F5F5] px-5 py-3 rounded-md shadow-md ${
            index === 0 ? "mb-2" : "my-2"
          }`}
        >
          <div className="flex justify-between">
            <p className="font-bold">{item?.position?.name}</p>
            <p
              onClick={() => {
                expSet(item);
                showSet(true);
              }}
              className="cursor-pointer font-bold"
            >
              Edit
            </p>
          </div>
          <p className="my-[3px]">{item?.company?.name}</p>
          <p className="my-[3px]">
            {get_month_and_year(item?.start_date)} -{" "}
            {item?.end_date ? get_month_and_year(item.end_date) : "Current"}
          </p>
        </div>
      ))}

      {show && (
        <div
          className={`capitalize bg-[#F5F5F5] px-5 py-3 rounded-md shadow-md my-2`}
        >
          <UpdateWorkExperience
            experience={exp}
            onCancel={() => {
              expSet(null);
              showSet(false);
            }}
          />
        </div>
      )}

      <div className="text-[#243677]">
        {!show && (
          <button
            type="button"
            className="border-none "
            onClick={() => showSet(true)}
          >
            + Add work experience
          </button>
        )}
      </div>
    </div>
  );
};

export default WorkExperience;
