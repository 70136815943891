import React from 'react'

// constants
import { FaEdit, FaSave } from 'react-icons/fa'

type IInputType = {
  name: string,
  value: string
  placeholder: string
}

type IDetails = {
    onSubmit?: (data: {}) => void
    title: string
    description?: string
    className?: string
    editable?: boolean
    inputs?: IInputType[]
}

const DetailEditInput: React.FC<IDetails> = ({ title, inputs, description, className, editable, onSubmit }) => {
    const [data, dataSet] = React.useState<{}>({});
    const [edit, editSet] = React.useState<boolean>(false)

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const {name, value} = e.target;
      dataSet(prev => ({...prev, [name]: value}));
    };

    const onLoad = React.useCallback(() => {
      if (inputs) {
        inputs.map(item => dataSet(prev => ({...prev, [item.name]: item.value})))
      }
    }, [inputs])

    React.useEffect(() => {
      onLoad();
    }, [onLoad])

    return (
      <div className={`w-full border-b border-[#4E4E4E] text-[#4E4E4E] text-[18px] flex py-3 ${className}`}>
        <div className='md:w-[30%] mr-2'>{title}</div>
        <div className='md:w-[70%] flex items-center justify-between pr-3'>
          <div>
            {!edit ? description : null}

            {inputs && edit && inputs.map(item => 
              <input
                key={item.name} 
                name={item.name} 
                value={data[item.name as keyof typeof data] || ""} 
                onChange={onChange} 
                className='border mx-1 outline-none focus:ring-0 rounded-lg px-1' 
              />)
            }
          </div>
          <div>
            {editable && !edit ? 
              <FaEdit onClick={() => editSet(!edit)} className='ml-3 cursor-pointer' /> 
              : editable && edit ? 
              <FaSave 
                onClick={() => {
                  if (Object.keys(data).length) {
                    onSubmit?.(data);
                  }
                  editSet(!edit)
                }} 
                className='ml-3 cursor-pointer' 
              /> 
              : null 
            }
          </div>
        </div>
      </div>
    )
}

export default DetailEditInput