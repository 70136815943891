import React from "react";

// components
import BioDataEdit from "../misc/BioDataEdit";
import SocialData from "../misc/SocialData";
import ProfileDataEdit from "../misc/EditProfileData";

// state
import { get_full_profile } from "../../../services/api/auth";
import { useAppDispatch } from "../../../store/hooks";

const ProfileEdit = () => {
  const dispatch = useAppDispatch();

  const onLoad = React.useCallback(() => {
    get_full_profile(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="w-full md:bg-[#F5F5F5] min-h-screen md:flex md:justify-between  px-5 py-3">
      <div className="md:w-[30%] w-[100%] block mr-5">
        <BioDataEdit />
        <SocialData />
      </div>
      <div className="md:w-[70%] w-[100%] block">
        <ProfileDataEdit />
      </div>
    </div>
  );
};

export default ProfileEdit;
