import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IWorkExperience } from "../types/auth";

// Define the initial state using that type
const initialState: {
  experiences: IWorkExperience[];
  loading: boolean;
  update: boolean;
} = {
  experiences: [],
  loading: false,
  update: false,
};

export const experience = createSlice({
  name: "experience",
  initialState,
  reducers: {
    setExperiences: (state, action: PayloadAction<IWorkExperience[]>) => {
      state.experiences = action.payload;
    },
    createExperience: (state, action: PayloadAction<IWorkExperience>) => {
      state.experiences = [action.payload, ...state.experiences];
    },
    updateExperiences: (state, action: PayloadAction<IWorkExperience>) => {
      state.experiences = state.experiences.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload;
        }
        return item;
      });
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
  },
});

export const {
  setExperiences,
  createExperience,
  updateExperiences,
  setLoading,
} = experience.actions;

export default experience.reducer;
