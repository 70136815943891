import React from "react";

const ConsentContent: React.FC = () => {
  return (
    <div>
      <h4 className="text-[17px] font-bold text-center md:text-[17px] mb-3">
        25th & Staffing Data Consent Form
      </h4>
      <p className="text-[17px] text-left md:text-[17px] mb-2">
        I hereby provide my consent to 25th and Staffing to collect, process,
        and store my personal data for the purposes of recruitment and
        employment-related activities. I understand and agree to the following
        terms:
      </p>
      <p className="text-[17px] text-left md:text-[17px] mb-2">
        I authorize and consent to send my official offer letter directly to
        25th and Staffing for verification purposes. I understand that this
        information will be used solely for the purpose of verifying my
        employment status and qualifications. I agree to release and hold
        harmless both the issuing organization and 25th and Staffing from any
        liability arising from the verification process.
      </p>
      <ol className="text-[17px] text-left md:text-[17px] list-decimal pl-4">
        <li className="mb-1">
          Purpose of Data Collection: The personal data collected will be used
          solely for recruitment, evaluation, and selection purposes, including
          assessing my suitability for employment and communicating with me
          regarding the recruitment process.
        </li>
        <li className="mb-1">
          Types of Personal Data Collected: The personal data collected may
          include, but is not limited to, my full name, contact details (such as
          address, email, and phone number), educational background, employment
          history, skills, and any other information relevant to the recruitment
          process.
        </li>
        <li className="mb-1">
          Data Processing and Storage: I understand that my personal data will
          be processed and stored securely by 25th and Staffing and its
          authorized representatives. I consent to the transfer of my personal
          data within Nigeria or to other countries, as required for recruitment
          and employment-related purposes, in compliance with applicable data
          protection laws.
        </li>
        <li className="mb-1">
          Data Retention: I acknowledge that my personal data will be retained
          for as long as necessary to fulfil the purposes outlined in this
          consent form, or as required by applicable laws and regulations.
        </li>
        <li className="mb-1">
          Data Sharing: I consent to the sharing of my personal data with
          relevant personnel involved in the recruitment process within 25th and
          Staffing. My personal data will not be disclosed to third parties
          without my prior consent, except where required by law.
        </li>
        <li className="mb-1">
          Access and Correction: I understand that I have the right to access,
          review, and correct my personal data held by 25th and Staffing. I may
          request the rectification or deletion of any inaccurate or incomplete
          information.
        </li>
        <li className="mb-1">
          Confidentiality and Security: I acknowledge that 25th and Staffing
          will handle my personal data in accordance with applicable laws and
          regulations. Reasonable technical and organizational measures will be
          implemented to safeguard my personal data against unauthorized access,
          loss, or misuse.
        </li>
      </ol>
    </div>
  );
};

export default ConsentContent;
