import React from "react";
// import useEducation from "../hooks/useEducation";

// components
import UpdateEducation from "./UpdateEducation";

// state and types
import { useAppSelector } from "../../../store/hooks";
import { FcGraduationCap } from "react-icons/fc";
import { get_month_and_year } from "../../../services/formatter/date";
import { IEducation } from "../../../store/types/auth";

const Education: React.FC = () => {
  const educations = useAppSelector(({ education }) => education.educations);
  const [show, showSet] = React.useState<boolean>(false);
  const [edu, eduSet] = React.useState<IEducation | null>(null);

  return (
    <div className="py-0 mb-3 font-abel">
      {educations?.map((item, index) => (
        <div
          key={index + 1}
          className={`capitalize relative bg-[#F5F5F5] px-5 py-3 rounded-md shadow-md ${
            index === 0 ? "mb-2" : "my-2"
          }`}
        >
          <div className="">
            {item.highest === 1 ? (
              <FcGraduationCap size={25} className="absolute -top-2 right-0" />
            ) : (
              ""
            )}
            <div className="flex justify-between">
              <p className="font-bold">{item?.college?.name}</p>
              <p
                onClick={() => {
                  showSet(true);
                  eduSet(item);
                }}
                className="cursor-pointer"
              >
                Edit
              </p>
            </div>
            <p className="my-[3px]">
              {item?.major?.name + ","} {item?.degree?.name}
            </p>
            <p className="my-[3px]">
              {item?.graduation
                ? get_month_and_year(item.graduation)
                : "Current"}
            </p>
          </div>
        </div>
      ))}

      {show && (
        <UpdateEducation
          education={edu}
          onDone={() => {
            showSet(false);
            eduSet(null);
          }}
        />
      )}

      <div className="text-[#243677]">
        {!show && (
          <button
            type="button"
            className="border-none "
            onClick={() => showSet(true)}
          >
            + Add education
          </button>
        )}
      </div>
    </div>
  );
};

export default Education;
