// import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";

// types and state
import {
  setCertificate,
  createCertification,
  updateCertificate,
  setLoading,
  deleteCertificate,
} from "../../store/slices/certificate";
import { IDataType } from "../../store/types/misc";
import { AppDispatch } from "../../store";
import { openNotification } from "../../components/ui/alerts";
import { isAxiosError } from "axios";

export const get_certification = async (dispatch: Function) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/get-certification");

    dispatch(setCertificate(response.data));
  } catch (error) {
    dispatch(setCertificate([]));
  }
  dispatch(setLoading());
};

export const create_cert = async (dispatch: AppDispatch, data: IDataType) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/create-certification", data);

    dispatch(createCertification(response.data?.data));
    openNotification({ message: response?.data?.message, status: "success" });
  } catch (error) {
    if (isAxiosError(error))
      openNotification({
        message: error?.response?.data?.message,
        status: "success",
      });
  }
  dispatch(setLoading());
};

export const update_cert = async (dispatch: AppDispatch, data: IDataType) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.patch("/update-certification", data);

    dispatch(updateCertificate(response.data?.data));
    openNotification({ message: response?.data?.message, status: "success" });
  } catch (error) {
    if (isAxiosError(error))
      openNotification({
        message: error?.response?.data?.message,
        status: "success",
      });
  }
  dispatch(setLoading());
};

export const delete_cert = async (dispatch: AppDispatch, id: string) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.delete(`/delete-certification/${id}`);

    dispatch(deleteCertificate(response.data?.data));
    openNotification({ message: response?.data?.message, status: "success" });
  } catch (error) {
    if (isAxiosError(error))
      openNotification({
        message: error?.response?.data?.message,
        status: "success",
      });
  }
  dispatch(setLoading());
};
