import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IDataType } from "../types/misc";

const initialState: {
  colleges: IDataType[];
  loading: boolean;
  update: boolean;
} = {
  colleges: [],
  loading: false,
  update: false,
};

export const college = createSlice({
  name: "college",
  initialState,
  reducers: {
    setCollege: (state, action: PayloadAction<IDataType[]>) => {
      state.colleges = action.payload;
    },
    updateCollege: (state, action: PayloadAction<IDataType>) => {
      state.colleges = [...state.colleges, action.payload];
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
  },
});

export const { setCollege, updateCollege, setLoading } = college.actions;

export default college.reducer;
