import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import TextInputBox from "../../../../components/elements/forms/TextInputBox";
import Button from "../../../../components/elements/forms/Button";
import { update_about } from "../../../../services/api/profile";

const UpdateAbout = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(({ auth }) => auth.user);
  const [show, showSet] = React.useState<boolean>(false);
  const [data, dataSet] = React.useState<string>("");

  const onLoad = React.useCallback(() => {
    if (user) {
      dataSet(user?.profile?.address?.about?.about_me || "");
    }
  }, [user]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="mb-3">
      <TextInputBox
        name="about"
        value={data}
        onChange={(e) => {
          dataSet(e.target.value);
          showSet(true);
        }}
        placeholder="Can we know about you 😊"
      />

      {show && (
        <div className="mb-3 mt-3 text-right">
          <Button
            title="Submit"
            type="button"
            onSubmit={() => {
              update_about(dispatch, { about: data });
              showSet(false);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default UpdateAbout;
