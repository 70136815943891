import React from "react";
import useSkills from "../hooks/useSkills";

// components
import CustomAutoComplete from "../../../components/elements/forms/CustomAutoComplete";
import TextInput from "../../../components/elements/forms/TextInput";

// state and types
import { useAppSelector } from "../../../store/hooks";
import Button from "../../../components/elements/forms/Button";

const Skill = () => {
  const roles = useAppSelector(({ misc }) => misc.roles);
  const user = useAppSelector(({ auth }) => auth.user);
  const {
    value,
    data,
    error,
    valueSet,
    onChange,
    onCreate,
    onChangeAutoComplete,
    onUpdateProfession,
  } = useSkills();

  const onLoad = React.useCallback(() => {
    if (data.profession) {
      const defaultRole = roles.find(
        (item) => item.id?.toString() === data.profession
      );
      defaultRole && valueSet(defaultRole.name);
    }
  }, [data.profession, roles, valueSet]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="mb-3">
      <div className="md:flex md:justify-between items-center">
        <CustomAutoComplete
          items={roles}
          container="w-[62%]"
          placeholder="Select Primary Role"
          value={value}
          onChange={(e) => valueSet(e.target.value)}
          onSelect={onChangeAutoComplete}
          onCreate={onCreate}
          status={error.profession}
        />
        <TextInput
          name="years_of_experience"
          container="my-2 w-[35%] ml-2"
          value={data.years_of_experience}
          type="number"
          onChange={onChange}
          placeholder="Years of Experience"
          status={error.years_of_experience}
        />
      </div>
      {user?.profile?.address?.about?.profession?.id?.toString() !==
        data.profession ||
      user?.profile?.address?.about?.years_of_experience?.toString() !==
        data.years_of_experience ? (
        <>
          <Button
            title="Save"
            className="mr-5 text-[white] bg-[#243677] border border-[#243677]"
            type="button"
            onSubmit={onUpdateProfession}
          />
          <Button title="Cancel" type="button" onSubmit={() => null} />
        </>
      ) : null}
    </div>
  );
};

export default Skill;
