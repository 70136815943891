import React from 'react'

// components
import CustomSelect from '../../../../components/elements/forms/CustomSelect'

// state and types
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { get_gender } from '../../../../services/api/misc'
import { update_gender } from '../../../../services/api/profile'

const UpdateGender: React.FC = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(({auth}) => auth.user);
  const genders = useAppSelector(({misc}) => misc.genders);
  const [data, dataSet] = React.useState<string>('')

  const onLoad = React.useCallback(() => {
    if (user) {
      dataSet(user?.profile?.gender_status?.id)
    }
  }, [user])

  const onState = React.useCallback(() => {
    get_gender(dispatch)
  }, [dispatch])

  React.useEffect(() => {
    onState()
    onLoad();
  }, [onLoad, onState])

  return (
    <div>
      <CustomSelect 
        items={genders} 
        selected={data} 
        placeholder='Please Select a Gender' 
        name="gender_id" 
        onSelect={(_name, value) => {
          dataSet(value)
          update_gender(dispatch, {name: value?.toString()})
        }}
      />
    </div>
  )
}

export default UpdateGender