// axios
import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";

// notification
import {
  openNotification,
  errorNotification,
} from "../../components/ui/alerts";

// auth Types
import { IChangePasswordData, ILogin } from "../../store/types/auth";
import { setLoading, stopLoading } from "../../store/slices/loading";
import { setUser } from "../../store/slices/auth";
import { CreateNewUser } from "../../store/types/auth";
import { setCountries, setGenders, setStates } from "../../store/slices/misc";
import { setMessage } from "../../store/slices/message";

export const check_email = async (formData: { email: string }) => {
  try {
    const response = await axiosInstance.post("/check-email", formData);

    if (response.data) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    // logger
  }
};

export const auth = async (formData: ILogin, dispatch: any, history: any) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/sign-in", formData);

    const { data } = response;
    // console.log("Respnse:", data);
    localStorage.setItem("@serial", data.serial);
    history("/");
  } catch (error) {
    if (isAxiosError(error)) {
      errorNotification({
        status: "error",
        message: error?.response?.data?.message,
      });
    } else {
      errorNotification({
        status: "error",
        message: "Please check your Internet.",
      });
    }
  }
  dispatch(stopLoading());
};

type userRegisterType = (
  dispatch: any,
  formData: CreateNewUser
) => Promise<void>;
export const userRegister: userRegisterType = async (dispatch, formData) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/sign-up", formData);

    if (response.data?.message === "This account already exist!") {
      throw new Error(response.data?.message);
    } else {
      dispatch(
        setMessage({
          type: "success",
          message: response?.data?.message,
          title: "Registration Successful",
        })
      );
    }
  } catch (error) {
    if (isAxiosError(error)) {
      dispatch(
        setMessage({
          type: "error",
          message: error?.response?.data?.message,
          title: "Failed",
        })
      );
    } else {
      // dispatch(setMessage({ type: 'error', message: error, title: 'Failed' }))
    }
  }
  dispatch(stopLoading());
};

type getUserData = (dispatch: any) => Promise<void>;
export const get_profile: getUserData = async (dispatch) => {
  try {
    const response = await axiosInstance.get("/get-profile");

    dispatch(setUser(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
};

type IForgotPassword = (
  dispatch: any,
  formData: { email: string },
  navigate: Function
) => void;
export const forgot_password: IForgotPassword = async (
  dispatch,
  formData,
  navigate
) => {
  dispatch(setLoading());
  try {
    await axiosInstance.post("/forgot-password", formData);

    localStorage.setItem("@email", formData?.email);
    navigate("/change-password");
  } catch (error) {
    if (isAxiosError(error)) {
      const { message } = error?.response?.data;
      errorNotification({ status: "error", message: message });
    } else {
      errorNotification({
        status: "error",
        message: "Please check your Internet",
      });
    }
  }
  dispatch(stopLoading());
};

type IChangePassword = (
  dispatch: Function,
  formData: IChangePasswordData,
  navigate: Function
) => Promise<void>;
export const change_password: IChangePassword = async (
  dispatch,
  formData,
  navigate
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/change-password", formData);

    openNotification({ status: "success", message: response?.data?.message });
    localStorage.removeItem("@email");
    navigate("/login");
  } catch (error) {
    if (isAxiosError(error)) {
      errorNotification({
        status: "error",
        message: error?.response?.data?.message,
      });
    } else {
      errorNotification({
        status: "error",
        message: "Please check yor internet.",
      });
    }
  }
  dispatch(stopLoading());
};

type GetFullUserData = (dispatch: any) => Promise<void>;
export const get_full_profile: GetFullUserData = async (dispatch) => {
  try {
    const response = await axiosInstance.get("/get-full-profile");

    dispatch(setUser(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
};

export const get_gender = async (dispatch: any) => {
  try {
    const response = await axiosInstance.get("/get-genders");

    dispatch(setGenders(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
};

export const get_countries = async (dispatch: any) => {
  try {
    const response = await axiosInstance.get("/get-countries");

    dispatch(setCountries(response.data));
  } catch (err) {
    if (isAxiosError(err)) {
    }
  }
};

export const get_states = async (id: number | string, dispatch: any) => {
  try {
    const response = await axiosInstance.get(`/get-states/${id}`);

    dispatch(setStates(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
};

export const logout_user = async (dispatch: any) => {
  localStorage.removeItem("@serial");
  await get_profile(dispatch);
};
