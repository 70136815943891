import React from "react";
import { Tabs } from "antd";
import type { TabsProps } from "antd";

// components
import UploadCV from "../misc/UploadCV";
import SubmitButton from "../../../components/elements/forms/SubmitButton";

// router dom
import { Link } from "react-router-dom";

// state
import { get_jobs } from "../../../services/api/jobs";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import JobCard from "../../../components/ui/cards/JobCard";
import LinkButton from "../../../components/elements/forms/LinkButton";
import StatsCandidates from "../misc/CandidateStats";

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(({ auth }) => auth?.user);
  const jobs = useAppSelector(({ jobs }) => jobs?.jobs);
  const [show, showSet] = React.useState<boolean>(false);

  const cv =
    user?.profile && user?.profile?.curriculum_vitae
      ? user?.profile?.curriculum_vitae
      : "";

  const onLoad = React.useCallback(() => {
    get_jobs(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Available Jobs",
      children: (
        <div className="font-abel">
          {jobs.map((job) => (
            <JobCard key={job.slug} item={job} />
          ))}

          <div className="flex justify-center items-center mt-6">
            <Link to="/jobs">
              <h2 className="text-[#243677] font-bold">View Other Jobs</h2>
            </Link>
          </div>
        </div>
      ),
    },
    {
      key: "2",
      label: "Previously Applied Jobs",
      children: (
        <div className="font-abel">
          {jobs.map((job) => (
            <JobCard key={job.slug} item={job} />
          ))}
        </div>
      ),
    },
  ];

  return (
    <div className="md:mx-10 mx-3 my-5">
      <section className="w-full flex items-center justify-end my-2 max-w-[300px] ml-auto mb-4">
        <SubmitButton
          title="Upload CV"
          className=""
          onSubmitHandler={() => showSet(true)}
        />
        <LinkButton
          title="View Profile"
          path={`/profile`}
          className="px-[15px] py-[8px] ml-2"
        />
      </section>

      <StatsCandidates />

      <section className="mt-6">
        <Tabs defaultActiveKey="1" items={items} />
      </section>

      <UploadCV
        visible={show}
        cv={cv}
        onHandleOk={() => showSet(false)}
        onCancel={() => showSet(false)}
      />
    </div>
  );
};

export default Dashboard;
