import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IDataType } from "../types/misc";

const initialState: {
  roles: IDataType[];
  loading: boolean;
  update: boolean;
} = {
  roles: [],
  loading: false,
  update: false,
};

export const role = createSlice({
  name: "role",
  initialState,
  reducers: {
    setRole: (state, action: PayloadAction<IDataType[]>) => {
      state.roles = action.payload;
    },
    updateRole: (state, action: PayloadAction<IDataType>) => {
      state.roles = [...state.roles, action.payload];
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
  },
});

export const { setRole, updateRole, setLoading } = role.actions;

export default role.reducer;
