import React from 'react'
import useLogin from './hooks';

// components
import TextInput from '../../../components/elements/forms/TextInput'

// theme
import Landing from '../app'

// assets
import SubmitButton from '../../../components/elements/forms/SubmitButton';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../store/hooks';

const Login: React.FC = () => {
  const loading = useAppSelector(({ loading }) => loading?.loading);
  const { data, error, onChangeHandler, onSubmitHandler } = useLogin()

  return (
    <Landing>
      <div className="md:flex font-abel md:py-10 md:flex-row bg-[#EFF2FE] md:justify-center md:items-center">
        <div className='bg-[white] px-4 py-10 items-center md:mx-5 md:min-w-[450px] border-none rounded-lg md:px-12 md:pt-8 md:pb-12 md:my-12'>
          <div className='text-left w-full my-5'>
            <h3 className='md:text-[30px] text-[23px] font-[900]'>Sign In</h3>
            {/* <span className='text-lg font-[700] text-[#aaaaaa]'> to continue</span> */}
          </div>

          <TextInput placeholder='Email address' status={error.email} name="email" value={data.email} onChange={onChangeHandler} />
          <TextInput placeholder='Password' status={error.password} name="password" type='password' value={data.password} onChange={onChangeHandler} container='my-3' />

          <div className='flex items-center mt-7 align-center'>
            <div className='w-[100%]'>
              <SubmitButton loading={loading} title='SIGN IN' onSubmitHandler={onSubmitHandler} className="bg-[#243677] font-[400] text-white md:hover:bg-[white] md:hover:text-[#243677] w-[30%]" />
            </div>
          </div>
          <div className='flex justify-between items-center mt-4 align-center'>
            <label>
              {/* <input type="checkbox" className="text-[#243677] checked:bg-blue-500" /> Keep me signed in */}
            </label>
            <Link to="/forgot-password" className='text-[#243677]'> Forgot Password</Link>
          </div>
          <div className='text-center mt-6'>
            <span>Don&apos;t have an account? <Link to="/register" className='text-[#243677]'> Sign Up</Link></span>
          </div>

        </div>
      </div>
    </Landing>
  )
}

export default Login