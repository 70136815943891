import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";

// notification
// import {openNotification, errorNotification} from '../../components/ui/alerts';

// types and state
import {
  setRoles,
  setReligions,
  setMaritalStatus,
  setGenders,
  setColleges,
  setDegrees,
  setCollegeMajor,
  setCompanies,
} from "../../store/slices/misc";
import { setLoading, stopLoading } from "../../store/slices/loading";
import { IDataType } from "../../store/types/misc";

type ISetRoles = (dispatch: any) => Promise<void>;
export const get_roles: ISetRoles = async (dispatch: Function) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/get-roles");

    dispatch(setRoles(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
  dispatch(stopLoading());
};

// obsolete
export const get_religions: ISetRoles = async (dispatch: Function) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/get-religions");

    dispatch(setReligions(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
  dispatch(stopLoading());
};

export const get_marital_statuses: ISetRoles = async (dispatch: Function) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/get-marital-status");

    dispatch(setMaritalStatus(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
  dispatch(stopLoading());
};

export const get_gender: ISetRoles = async (dispatch: Function) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/get-genders");

    dispatch(setGenders(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
  dispatch(stopLoading());
};

export const get_colleges = async (dispatch: Function) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/get-colleges");

    dispatch(setColleges(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
  dispatch(stopLoading());
};

export const get_degrees = async (dispatch: Function) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/get-degrees");

    dispatch(setDegrees(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
  dispatch(stopLoading());
};

export const get_college_degrees = async (dispatch: Function) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/get-college-major");

    dispatch(setCollegeMajor(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
  dispatch(stopLoading());
};

// obsolete
export const get_companies = async (dispatch: Function) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/get-companies");

    dispatch(setCompanies(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
  dispatch(stopLoading());
};

type IPostCreateRole = (
  dispatch: Function,
  formData: { name: string }
) => Promise<IDataType | void>;
export const create_role: IPostCreateRole = async (
  dispatch: Function,
  formData
) => {
  try {
    const response = await axiosInstance.post("/create-role", formData);

    get_roles(dispatch);
    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
};

export const create_college: IPostCreateRole = async (
  dispatch: Function,
  formData
) => {
  try {
    const response = await axiosInstance.post("/create-college", formData);

    get_colleges(dispatch);
    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
};

// obsolete
export const create_company: IPostCreateRole = async (
  dispatch: Function,
  formData
) => {
  try {
    const response = await axiosInstance.post("/create-company", formData);

    get_companies(dispatch);
    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
};

export const create_college_major: IPostCreateRole = async (
  dispatch: Function,
  formData
) => {
  try {
    const response = await axiosInstance.post(
      "/create-college-major",
      formData
    );

    get_college_degrees(dispatch);
    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
};

export const create_degree: IPostCreateRole = async (
  dispatch: Function,
  formData
) => {
  try {
    const response = await axiosInstance.post("/create-degree", formData);

    get_degrees(dispatch);
    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
};
