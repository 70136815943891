import React from 'react'

// components
import JobCard from '../../../components/ui/cards/JobCard';

// state
import { get_jobs } from '../../../services/api/jobs';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
// constants
import { FaSearch } from 'react-icons/fa';
import SearchInput from '../../../components/elements/forms/SearchInput';

const Jobs = () => {
  const dispatch = useAppDispatch()
  const jobs = useAppSelector(({ jobs }) => jobs.jobs);
  const [search, setSearch] = React.useState<string>('');

  const onLoad = React.useCallback(() => {
    get_jobs(dispatch)
  }, [dispatch]);

  React.useEffect(() => {
    onLoad()
  }, [onLoad])

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    if (search.length > 2) {
      // get_jobs(dispatch, search);
    }
  };

  const search_icon = <FaSearch color="#243677" />;

  return (
    <div className='w-full md:px-5 md:py-4'>
      <div className="my-2 w-full text-right justify-between">
        <div className="w-4/12"></div>
        <div className="w-4/12">
          <SearchInput name="Search" placeholder="Search..." value={search} onChange={onSearch} icon={search_icon} />
        </div>
      </div>
      {jobs.map((job) => <JobCard key={job.slug} item={job} />)}
    </div>
  )
}

export default Jobs