import React from 'react'

// components
import TextInput from '../../../../components/elements/forms/TextInput';
import Button from '../../../../components/elements/forms/Button';

// state and services
import { IUpdateNameData } from '../../../../store/types/profile';
import { update_full_name } from '../../../../services/api/profile';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'

const FullNameUpdate = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(({auth}) => auth.user);
  const [data, dataSet] = React.useState<IUpdateNameData>({
    first_name: '',
    last_name: ''
  })

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target
    dataSet(prev => ({...prev, [name]: value}))
  }

  const onLoad = React.useCallback(() => {
    if (user) {
      dataSet(prev => ({...prev, first_name: user?.profile?.first_name, last_name: user?.profile.last_name}))
    }
  }, [user])

  React.useEffect(() => {
    onLoad()
  }, [onLoad])

  return (
    <div className='w-full'>
      <div className="flex w-full"> 
        <TextInput value={data.first_name} container="w-[49%] mr-2" name="first_name" placeholder='FirstName' onChange={onChange} />
        <TextInput value={data.last_name} container="w-[49%]" name="last_name" placeholder='LastName' onChange={onChange} />
      </div>
      {data.first_name !== user?.profile?.first_name || data.last_name !== user?.profile?.last_name ? 
        <div className='w-full text-right my-2'>
          <Button title="Save" type="button" onSubmit={() => update_full_name(dispatch, data)} />
        </div>
        : 
        ''
      }
    </div>
  )
}

export default FullNameUpdate