import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IDataType } from "../types/misc";

const initialState: {
  courses: IDataType[];
  loading: boolean;
} = {
  courses: [],
  loading: false,
};

export const course = createSlice({
  name: "course",
  initialState,
  reducers: {
    setCourse: (state, action: PayloadAction<IDataType[]>) => {
      state.courses = action.payload;
    },
    updateCourse: (state, action: PayloadAction<IDataType>) => {
      state.courses = [...state.courses, action.payload];
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
  },
});

export const { setCourse, updateCourse, setLoading } = course.actions;

export default course.reducer;
