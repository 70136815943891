import React from "react";
import {
  IChangePasswordData,
  IChangePasswordError,
} from "../../../store/types/auth";
import { change_password } from "../../../services/api/auth";
import { useAppDispatch } from "../../../store/hooks";

// react router
import { useNavigate } from "react-router-dom";

const useChangePassword = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [visible, visibleSet] = React.useState<boolean>(false);
  const [data, dataSet] = React.useState<IChangePasswordData>({
    email: "",
    password: "",
    password_confirmation: "",
    token: "",
  });
  const [err, errSet] = React.useState<IChangePasswordError>({
    email: "",
    password: "",
    password_confirmation: "",
    token: "",
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dataSet((prev) => ({ ...prev, [name]: value }));
    errSet((prev) => ({ ...prev, [name]: "" }));
  };

  type IOnValidate = () => void;
  const onValidate: IOnValidate = () => {
    let err: boolean = false;
    if (!data.email) {
      err = true;
    }
    if (!data.password) {
      err = true;
      errSet((prev) => ({ ...prev, password: "warning" }));
    }
    if (data.password !== data.password_confirmation) {
      err = true;
      errSet((prev) => ({
        ...prev,
        password: "warning",
        password_confirmation: "warning",
      }));
    }
    if (err) {
      return;
    }
    change_password(dispatch, data, navigate);
  };

  const onValidateToken = () => {
    if (data.token.length !== 6) {
      return;
    }
    visibleSet(!visible);
  };

  return {
    err,
    errSet,
    data,
    dataSet,
    visible,
    onChange,
    visibleSet,
    onValidate,
    onValidateToken,
  };
};

export default useChangePassword;
