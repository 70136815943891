import React from "react";

type ITabHeader = {
  title: string
  icon?: React.ReactNode
}

const TabHeader: React.FC<ITabHeader> = ({title, icon}) => {
  return (
    <div className="px-2 text-[#243677] flex items-center justify-center font-abel">
      {icon} <p className="mx-2">{title}</p>
    </div>);
};

export default TabHeader;