import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";

// notification
import {
  openNotification,
  errorNotification,
} from "../../components/ui/alerts";

// types and state
import { get_full_profile } from "./auth";
import {
  IProfile,
  IAboutMe,
  ISocial,
  IUpdateNameData,
  IUpdateAddress,
  IPrimaryProfession,
  IUpdatePhoneNumber,
} from "../../store/types/profile";
import { setUpdate, stopUpdate } from "../../store/slices/loading";
import { IEducationData, IWorkExperienceData } from "../../store/types/auth";

type IUpadteFullName = (
  dispatch: Function,
  formData: IUpdateNameData
) => Promise<void>;
export const update_full_name: IUpadteFullName = async (dispatch, formData) => {
  try {
    await axiosInstance.post("/update-full-name", formData);
    get_full_profile(dispatch);
  } catch (error) {}
};

type IUpdatePhone = (
  dispatch: Function,
  data: IUpdatePhoneNumber
) => Promise<void>;
export const update_phone_number: IUpdatePhone = async (dispatch, data) => {
  try {
    await axiosInstance.post("/update-phone-number", data);
    get_full_profile(dispatch);
  } catch (error) {}
};

type IUpdateDOB = (dispatch: Function, data: { name: string }) => Promise<void>;
export const update_dob: IUpdateDOB = async (dispatch, data) => {
  try {
    await axiosInstance.post("/update-date-of-birth", data);
    get_full_profile(dispatch);
  } catch (error) {}
};

type IUpadteAddress = (
  dispatch: Function,
  formData: IUpdateAddress
) => Promise<void>;
export const update_address: IUpadteAddress = async (dispatch, formData) => {
  try {
    await axiosInstance.post("/update-address", {
      address: formData.address,
      state_id: formData.state_id?.toString(),
      country_id: formData.country_id?.toString(),
    });
    get_full_profile(dispatch);
  } catch (error) {}
};

type IUpadteAbout = (
  dispatch: Function,
  formData: { about: string }
) => Promise<void>;
export const update_about: IUpadteAbout = async (dispatch, formData) => {
  try {
    await axiosInstance.post("/update-about-me", formData);
    get_full_profile(dispatch);
  } catch (error) {}
};

type ISocialFunc = (dispatch: Function, formData: ISocial) => Promise<void>;
export const update_social_me: ISocialFunc = async (dispatch, formData) => {
  try {
    console.log(formData);
    await axiosInstance.post("/update-social-me", formData);
    get_full_profile(dispatch);
  } catch (error) {
    if (isAxiosError(error)) {
      console.log(error?.response?.data);
    }
  }
};

type IUpdateGender = (
  dispatch: Function,
  formData: { name: string }
) => Promise<void>;
export const update_gender: IUpdateGender = async (dispatch, formData) => {
  try {
    await axiosInstance.post("/update-gender", formData);
    get_full_profile(dispatch);
  } catch (error) {
    if (isAxiosError(error)) {
      // console.log(error?.response?.data);
    }
  }
};

type IUpdateWorkExperience = (
  dispatch: Function,
  formData: IWorkExperienceData
) => Promise<void>;
export const save_work_experience: IUpdateWorkExperience = async (
  dispatch,
  formData
) => {
  try {
    await axiosInstance.post("/save-work-expereince", formData);
    get_full_profile(dispatch);
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
};

export const update_work_experience: IUpdateWorkExperience = async (
  dispatch,
  formData
) => {
  try {
    await axiosInstance.post("/update-work-expereince", formData);
    get_full_profile(dispatch);
  } catch (error) {
    if (isAxiosError(error)) {
      // console.log(error?.response?.data);
    }
  }
};

type IUpdateEducation = (
  dispatch: Function,
  formData: IEducationData
) => Promise<void>;
export const save_education: IUpdateEducation = async (dispatch, formData) => {
  try {
    // console.log(formData);
    await axiosInstance.post("/save-education", formData);
    get_full_profile(dispatch);
  } catch (error) {
    if (isAxiosError(error)) {
      // console.log(error?.response?.data);
    }
  }
};

export const update_education: IUpdateEducation = async (
  dispatch,
  formData
) => {
  try {
    await axiosInstance.post("/update-education", formData);
    get_full_profile(dispatch);
  } catch (error) {
    if (isAxiosError(error)) {
      // console.log(error?.response?.data);
    }
  }
};

type IUpdateProfession = (
  dispatch: Function,
  formData: IPrimaryProfession
) => Promise<void>;
export const update_profession: IUpdateProfession = async (
  dispatch,
  formData
) => {
  try {
    await axiosInstance.post("/update-profession", formData);
    get_full_profile(dispatch);
  } catch (error) {
    if (isAxiosError(error)) {
      // console.log(error?.response?.data);
    }
  }
};

/**
 * obsolete
 */
type ISetRoles = (dispatch: Function, formData: IProfile) => Promise<boolean>;
export const update_profile: ISetRoles = async (dispatch, formData) => {
  let result: boolean = false;
  dispatch(setUpdate());
  try {
    await axiosInstance.post("/update-profile", formData);

    result = true;
  } catch (error) {
    result = false;
    if (isAxiosError(error)) {
    }
  }
  dispatch(stopUpdate());
  return result;
};

type IAboutMeFunc = (
  dispatch: Function,
  formData: IAboutMe
) => Promise<boolean>;
export const update_about_me: IAboutMeFunc = async (dispatch, formData) => {
  let result: boolean = false;
  dispatch(setUpdate());
  try {
    await axiosInstance.post("/update-about-me", formData);

    result = true;
  } catch (error) {
    result = false;
    if (isAxiosError(error)) {
    }
  }
  dispatch(stopUpdate());
  return result;
};

type IPosition = (
  dispatch: Function,
  formData: { position: string }
) => Promise<boolean>;
export const update_position: IPosition = async (dispatch, formData) => {
  let result: boolean = false;
  dispatch(setUpdate());
  try {
    await axiosInstance.post("/update-profesional-position", formData);

    result = true;
  } catch (error) {
    result = false;
    if (isAxiosError(error)) {
    }
  }
  dispatch(stopUpdate());
  return result;
};

// export const update_phone_number = async (dispatch: Function, formData: {}) => {
//   dispatch(setUpdate());
//   try {
//     const response = await axiosInstance.post("/update-phone-number", formData);

//     openNotification({ status: "success", message: response.data });
//     get_full_profile(dispatch);
//   } catch (error) {
//     errorNotification({
//       status: "error",
//       message: "Please the necessary field",
//     });
//   }
//   dispatch(stopUpdate());
// };

export const update_marital_status = async (
  dispatch: Function,
  formData: {}
) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.post(
      "/update-marital-status",
      formData
    );

    openNotification({ status: "success", message: response.data });
    get_full_profile(dispatch);
  } catch (error) {
    errorNotification({
      status: "error",
      message: "Please the necessary field",
    });
  }
  dispatch(stopUpdate());
};

export const update_residential_state = async (
  dispatch: Function,
  formData: {}
) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.post(
      "/update-residential-state",
      formData
    );

    openNotification({ status: "success", message: response.data });
    get_full_profile(dispatch);
  } catch (error) {
    errorNotification({
      status: "error",
      message: "Please the necessary field",
    });
  }
  dispatch(stopUpdate());
};
