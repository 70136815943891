import React from 'react'

// routes
import { Route, Routes } from "react-router-dom";

// themes
import Theme from '../../themes';

// components
import { Dashboard } from '../../screens/dashboard';
import { Profile } from '../../screens/profile';
import { Job, Jobs } from '../../screens/jobs';
import ProfileEdit from '../../screens/profile/edit';

const AuthRoute: React.FC = () => {
  return (
    <Theme>
      <Routes>
        {/* jobs */}
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/job/:id" element={<Job />} />

        {/* profile */}
        <Route path="/profile" element={<Profile />} />
        <Route path="/edit-profile" element={<ProfileEdit />} />

        {/* Home */}
        <Route path="/" element={<Dashboard />} />
      </Routes>
    </Theme>
  )
}

export default AuthRoute