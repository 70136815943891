import React from "react";

// components

type TextInputProps = {
  container?: string;
  status?: "warning" | "";
  label?: string;
  prefix?: React.ReactNode;
  input_style?: string;
  type?: "text" | "password" | "number";
  placeholder: string;
  className?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string | number | [];
  name: string;
  required?: boolean;
  onBlur?: () => void;
};

const TextInput: React.FC<TextInputProps> = ({
  container,
  status,
  label,
  required,
  prefix,
  input_style,
  type = "text",
  onChange,
  placeholder,
  value,
  name,
  onBlur,
}) => {
  const borderColor = status ? "border-[red]" : "border-[#e8e8e8]";
  return (
    <div className={`${container} font-abel`}>
      {label && (
        <label className="font-[600] block text-[#303030] capitalize`">
          {label} {required && <span className="text-[red]">*</span>}
        </label>
      )}
      <div
        className={`flex items-center h-[48px] text-xl py-3 px-4 rounded-lg border hover:border-[#243677] bg-[#ffffff] focus-within:border-[#243677] ${input_style} ${borderColor}`}
      >
        {prefix && <div className="pr-2">{prefix}</div>}
        <div className="w-full bg-[#fff]">
          <input
            name={name}
            type={type}
            value={value}
            onBlur={onBlur}
            placeholder={placeholder}
            onChange={onChange}
            className="border-none placeholder:font-abel placeholder:text-[15px] text-[15px] outline-none w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default TextInput;
