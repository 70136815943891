import React from 'react'

// types
import { CreateNewUser, ErrorCreateNewUser } from '../../../store/types/auth';

// state and types
import { useAppDispatch } from '../../../store/hooks';
import { ErrorWarning } from '../../../store/types/misc';
import { userRegister, check_email } from '../../../services/api/auth';
import { validateEmail } from '../../../services/formatter/validate';

const useRegister = () => {
  const dispatch = useAppDispatch()
  const [emailExist, emailExistSet] = React.useState<ErrorWarning>('')
  const [user, userSet] = React.useState<CreateNewUser>({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    password_confirmation: '',
    consent: false,
  });

  const [userErr, userErrSet] = React.useState<ErrorCreateNewUser>({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    password_confirmation: '',
    consent: '',
  });

  const onChangeHandlerUser = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    userErrSet(prev => ({ ...prev, [name]: '' }))
    userSet(prev => ({ ...prev, [name]: value }))
    if (name === 'email') {
      emailExistSet('');
    }
  }

  const onCheck = () => {
    userSet(prev => ({ ...prev, consent: !user.consent }))
    userErrSet(prev => ({ ...prev, consent: '' }))

  }

  const onValidateUser = (): boolean => {
    let error: boolean = true;
    if (!user.first_name) {
      userErrSet(prev => ({ ...prev, first_name: "warning" }))
      error = false
    }
    if (!user.last_name) {
      userErrSet(prev => ({ ...prev, last_name: "warning" }))
      error = false
    }
    if (!user.email || !validateEmail(user.email) || emailExist) {
      userErrSet(prev => ({ ...prev, email: "warning" }))
      error = false
    }
    if (!user.password) {
      userErrSet(prev => ({ ...prev, password: "warning" }))
      error = false
    }
    if (!user.consent) {
      userErrSet(prev => ({ ...prev, consent: "warning" }))
      error = false
    }
    if (!user.password || user.password_confirmation !== user.password) {
      userErrSet(prev => ({ ...prev, password_confirmation: "warning", password: 'warning' }))
      error = false
    }
    return error;
  }

  const onSubmitUser = async () => {
    if (onValidateUser()) {
      await userRegister(dispatch, user);
    }
  }

  const onCheckEmail = async () => {
    if (validateEmail(user.email)) {
      const response = await check_email({ email: user.email });
      if (response) {
        emailExistSet('warning');
        userErrSet(prev => ({ ...prev, email: 'warning' }))
      }
    } else {
      userErrSet(prev => ({ ...prev, email: 'warning' }))
    }

  }

  return { user, emailExist, userErr, onChangeHandlerUser, onSubmitUser, onCheckEmail, onCheck }
}

export default useRegister