import React from 'react'

// router
import { Link } from 'react-router-dom'

type IButtonInterface = {
    title: string
    path: string
    className?: string
}

const LinkButton:React.FC<IButtonInterface> = ({title, path, className}) => {
  return (
    <Link to={path} preventScrollReset={true}>
      <span className={`text-center text-[white] bg-[#243677] md:py-2 md:px-8 rounded duration-500 ${className}`}>
        {title}
      </span>
    </Link>
  )
}

export default LinkButton