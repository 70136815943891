import React from "react";

const ConsentContent2: React.FC = () => {
  return (
    <div>
      <h4 className="text-[17px] font-bold text-center md:text-[17px] mb-3">
        RIGHT TO REPRESENT CANDIDATE CONSENT FORM
      </h4>
      <p className="text-[17px] text-left md:text-[17px] mb-2">
        I hereby authorize 25th & Staffing to begin to take steps, effective
        today, to attempt to assign me to work for one or other of its clients.
        This by no means constitutes an employee/employer relationship.
      </p>
      <p className="text-[17px] text-left md:text-[17px] mb-2">
        I understand that this Agreement provides no guarantee that 25th &
        Staffing will be successful in its attempts to assign me to work and
        that this agreement therefore does not guarantee me future work
        assignments with any clients of 25th & Staffing.
      </p>
      <p className="text-[17px] text-left md:text-[17px] mb-2">
        I consent to the collection of my Personal Information, as requested by
        25th & Staffing and I understand that this information will be used
        strictly for the purpose of assisting me in finding a suitable
        employment opportunity.
      </p>
      <p className="text-[17px] text-left md:text-[17px] mb-2">
        By engaging with our recruitment services, you agree to honor our role
        as an intermediary. Any direct employment arrangements with our client
        companies, whether initiated through our job postings, applications, or
        introductions, must involve our agency. Circumventing this process may
        lead to contractual violations and potential legal consequences. We
        value your cooperation in upholding the integrity of our professional
        relationships.
      </p>
      <p className="text-[17px] text-left md:text-[17px] mb-2">
        I consent to the disclosure of such Personal Information to:
      </p>
      <ol className="text-[17px] text-left md:text-[17px] list-decimal pl-4">
        <li className="mb-1">
          To 25th & Staffing's’ clients when, in 25th & Staffing’s exclusive
          discretion, the client may provide me with a suitable employment
          opportunity; and to the extent necessary, to 25th & Staffing’s
          representatives (including, but not limited to, affiliates, partners
          and contractors) who assist 25th & Staffing in providing full staffing
          services.
        </li>
        <li className="mb-1">
          I further consent to the retention of my personal information by 25th
          & Staffing as long as I am utilizing 25th & Staffing’s placement
          services.
        </li>
        <li className="mb-1">
          In the event that I wish to withdraw my consent to the use, disclosure
          and retention of my personal information, I understand that it is my
          responsibility to inform 25th & Staffing in writing, at which time I
          will be informed by 25th & Staffing if and/or how such withdrawal may
          affect the services being provided to me.
        </li>
        <li className="mb-1">
          I fully understand the above consent statements, and accept same
          voluntarily, as certified by my agreement below.
        </li>
      </ol>
      <p className="text-[17px] text-left md:text-[17px]">
        By signing below, I confirm that I have read and understood this 25th
        and Staffing Data Consent Form and freely give my consent for the
        processing and storage of my personal data as described above.
      </p>
    </div>
  );
};

export default ConsentContent2;
