// import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";

// types and state
import {
  setCollege,
  updateCollege,
  setLoading,
} from "../../store/slices/college";

export const get_colleges = async (dispatch: Function) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/get-colleges");

    dispatch(setCollege(response.data));
  } catch (error) {
    dispatch(setCollege([]));
  }
  dispatch(setLoading());
};

export const create_college = async (
  dispatch: Function,
  data: { name: string }
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/create-college", data);

    dispatch(updateCollege(response.data?.data));
  } catch (error) {
    // dispatch(setRole([]));
  }
  dispatch(setLoading());
};
