import React from 'react'

// components
import { Spin } from 'antd';

type ButtonProps = {
  title: string;
  onHandlePress: () => void;
  className?: string;
  loading?: boolean
}

const ActionButton: React.FC<ButtonProps> = ({ title, onHandlePress, className, loading }) => {
  return (
    <button type="button" onClick={onHandlePress} className={`text-center text-[13px] md:text-[16px] rounded-lg font-bold font-abel bg-[#243677] hover:bg-[#243677] hover:bg-opacity-40 hover:text-[#243677] hover:font-bold py-2 px-6 md:ml-4 ml-4 duration-500 ${className}`}>
      {loading && <Spin />} {title}
    </button>
  )
}

export default ActionButton