import React from "react";

// components
import DetailEditInput from "../../../components/ui/cards/DetailEditInput";
import DetailEditSelect from "../../../components/ui/cards/DetailEditSelect";
// import DetailEdit from '../../../components/ui/cards/DetailEdit';

// state
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  //  update_full_name, update_address,
  // update_phone_number,
  update_marital_status,
  update_residential_state,
} from "../../../services/api/profile";
import { get_gender, get_marital_statuses } from "../../../services/api/misc";
import DetailEditStateCountrySelect from "../../../components/ui/cards/DetailEditStateCountrySelect";

const ProfileDataEdit = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(({ auth }) => auth.user);
  const genders = useAppSelector(({ misc }) => misc.genders);
  const marital_statuses = useAppSelector(({ misc }) => misc.marital_statuses);

  const onLoad = React.useCallback(() => {
    get_gender(dispatch);
    get_marital_statuses(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="px-5 py-7 bg-[white]">
      <DetailEditInput
        title="Full Name: "
        editable={true}
        inputs={[
          {
            name: "first_name",
            value: user?.profile?.first_name || "",
            placeholder: "First Name",
          },
          {
            name: "last_name",
            value: user?.profile?.last_name || "",
            placeholder: "Last Name",
          },
        ]}
        description={
          user ? user?.profile?.first_name + " " + user?.profile?.last_name : ""
        }
        onSubmit={
          (response) => null
          // update_full_name(dispatch, response)
        }
      />

      <DetailEditInput
        title="Email: "
        editable={false}
        description={user ? user?.email : ""}
      />

      <DetailEditInput
        title="Phone Number: "
        editable={true}
        inputs={[
          {
            name: "phone",
            value: user?.profile?.phone || "",
            placeholder: "Phone Number",
          },
        ]}
        description={user ? user?.profile?.phone : ""}
        onSubmit={(res) => null}
      />

      <DetailEditSelect
        title="Gender: "
        className="capitalize"
        editable={true}
        inputs={[
          {
            name: "name",
            value: user?.profile?.gender_status?.id || "",
            placeholder: "Select Gender",
            options: genders,
            selectedItem: user?.profile?.gender_status?.id || "",
          },
        ]}
        description={user ? user?.profile?.gender_status?.name : ""}
        onSubmit={(res) => null}
      />

      <DetailEditSelect
        title="Marriage: "
        editable={true}
        className="capitalize"
        inputs={[
          {
            name: "name",
            value: user?.profile?.address?.about?.marital_status?.id || "",
            placeholder: "Select Marital Status",
            options: marital_statuses,
            selectedItem:
              user?.profile?.address?.about?.marital_status?.id || "",
          },
        ]}
        description={
          user && user?.profile?.address?.about
            ? user?.profile?.address?.about?.marital_status?.name
            : ""
        }
        onSubmit={(res) => update_marital_status(dispatch, res)}
      />

      <DetailEditInput
        title="DOB: "
        editable={false}
        description={user ? user?.profile?.dob : ""}
      />

      <DetailEditInput
        title="Address: "
        editable={true}
        className="capitalize"
        inputs={[
          {
            name: "address",
            value: user?.profile?.address?.address || "",
            placeholder: "Address",
          },
        ]}
        description={
          user && user?.profile?.address ? user?.profile?.address?.address : ""
        }
        onSubmit={(res) => console.log(res)}
        // onSubmit={res => update_address(dispatch, res)}
      />

      <DetailEditStateCountrySelect
        title="Residential: "
        className="capitalize"
        editable={true}
        selectedCountry={
          user?.profile?.address?.residential_state?.country?.name || ""
        }
        selectedState={user?.profile?.address?.residential_state?.id || ""}
        description={
          user && user?.profile?.address
            ? user?.profile?.address?.residential_state?.name +
              " " +
              user?.profile?.address?.residential_state?.country?.name
            : ""
        }
        onSubmit={(res) => {
          if (res.state_id) {
            update_residential_state(dispatch, { name: res?.state_id });
          }
        }}
      />

      <DetailEditInput
        title="Nationality & Origin: "
        className="capitalize"
        editable={false}
        description={
          user && user?.profile?.address
            ? user?.profile?.address?.origin_state?.name +
              " " +
              user?.profile?.address?.origin_state?.country?.name
            : ""
        }
      />
    </div>
  );
};

export default ProfileDataEdit;
