import React from 'react'
import { Spin } from 'antd'

type ISlimSelect = {
  placeholder: string
  name: string
  onSelect: (e: React.ChangeEvent<HTMLInputElement>) => void
  loading?: boolean
}

const SlimSelect: React.FC<ISlimSelect> = ({name, loading, placeholder, onSelect}) => {
  return (
    <div className='w-full m-0 font-abel'>
      <div onClick={() => document.getElementById(name)?.click()} className={`w-full text-[15px] rounded-md py-[10px] px-4 cursor-pointer border ${loading ? 'border-[#243677] bg-[white] text-[#243677]' : 'bg-[#243677] text-[white]'}`}>
        {placeholder} {loading && <Spin className="mx-2" />}
      </div>
      <input type="file" disabled={loading ? true : false} id={name} name={name} onChange={onSelect} hidden />
    </div>
  )
}

export default SlimSelect
