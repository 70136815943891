// import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";

// types and state
import { setLoading, setRole, updateRole } from "../../store/slices/role";

export const get_roles = async (dispatch: Function) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/get-roles");

    dispatch(setRole(response.data));
  } catch (error) {
    dispatch(setRole([]));
  }
  dispatch(setLoading());
};

export const create_role = async (
  dispatch: Function,
  data: { name: string }
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/create-role", data);

    dispatch(updateRole(response.data));
  } catch (error) {
    // dispatch(setRole([]));
  }
  dispatch(setLoading());
};
