import React from 'react'

// types and state
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../store/hooks';
import { create_role, get_roles } from '../../../services/api/misc';
import { CustomSelectItem } from '../../../store/types/misc';
import { IPrimaryProfession, IPrimaryProfessionError } from '../../../store/types/profile';
import { update_profession } from '../../../services/api/profile';

const useSkills = () => {
  const dispatch = useDispatch();
  const user = useAppSelector(({auth}) => auth.user);
  const [value, valueSet] = React.useState<string>('')
  const [data, dataSet] = React.useState<IPrimaryProfession>({
    profession: '',
    years_of_experience: ''
  })
  const [error, errorSet] = React.useState<IPrimaryProfessionError>({
    years_of_experience: '',
    profession: ''
  })

  const onSetData = React.useCallback(() => {
    if (user && user.profile && user.profile.address && user.profile.address.about && user.profile.address.about.profession) {
      dataSet(prev => ({
        ...prev, 
        profession: user?.profile?.address?.about?.profession?.id?.toString() || "",
        years_of_experience: user?.profile?.address?.about?.years_of_experience?.toString() || ""
      }))
    }
  }, [user])

  const onLoad = React.useCallback(() => {
    get_roles(dispatch)
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
    onSetData();
  }, [onLoad, onSetData])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value, name} = e.target
    errorSet(prev => ({...prev, [name]: ''}))
    dataSet(prev => ({...prev, [name]: value?.toString()}))
  }

  const onChangeAutoComplete = (res: CustomSelectItem) => {
    valueSet(res.name);
    errorSet(prev => ({...prev, profession: ''}))
    dataSet(prev => ({...prev, profession: res.id?.toString()}))
  }

  const onCreate = async (res: string) => {
    const response = await create_role(dispatch, {name: res})
    if (response) {
      dataSet(prev => ({...prev, profession: response.id}))
      valueSet(response.name)
    }
  }

  const onUpdateProfession = () => {
    let validate: boolean = false;
    if (!data.profession) {
      validate = true;
      errorSet(prev => ({...prev, profession: 'warning'}))
    }
    if (!data.years_of_experience) {
      validate = true;
      errorSet(prev => ({...prev, years_of_experience: 'warning'}))
    }
    if (validate) {
      return
    }
    update_profession(dispatch, data)
  }

  return {value, data, error, valueSet, dataSet, onChange, onCreate, onUpdateProfession, onChangeAutoComplete}
}

export default useSkills
