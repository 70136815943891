import React from 'react'
import SubmitButton from '../../../components/elements/forms/SubmitButton'
import UploadCV from '../../dashboard/misc/UploadCV';
import { useAppSelector } from '../../../store/hooks';

const ResumeCv = () => {
  const user = useAppSelector(({ auth }) => auth?.user);
  const [show, showSet] = React.useState<boolean>(false);

  const cv = user?.profile && user?.profile?.curriculum_vitae ? user?.profile?.curriculum_vitae : ''

  return (
    <div>
      <section className='w-full flex items-center justify-start flex-wrap my-2 px-6'>
        <div className='font-abel mr-4 my-2'>
          <p className='text-[#243677] font-bold text-lg'>Upload your resent resume or CV</p>
          <p>Upload your most up-to-date resume</p>
          <p>File type: PDF only</p>
        </div>
        <SubmitButton title="Upload CV" className='w-[150px] md:w-[20%] my-2' onSubmitHandler={() => showSet(true)} />
      </section>
      <section className='h-[80vh]'>
        <object data={cv || ""} type="application/pdf" width="100%" height="100%">
          <p>Alternative text - include a link <a href={cv || ""}>to the PDF!</a></p>
        </object>
      </section>
      <UploadCV
        visible={show}
        cv={cv}
        onHandleOk={() => showSet(false)}
        onCancel={() => showSet(false)}
      />
    </div>
  )
}

export default ResumeCv