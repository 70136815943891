
// constants
import user_avatar from '../../../assets/images/temporary/user.png'

// state
import { useAppSelector } from '../../../store/hooks'
// import ProgressActive from '../../../components/ui/progress/ProgressActive';

const BioDataEdit = () => {
    const user = useAppSelector(({ auth }) => auth.user);

    const avatar = user?.profile?.avatar || user_avatar
    return (
        <div className='px-5 py-4 w-full text-center text-[#4E4E4E] bg-[white]'>
            <div className='flex justify-center items-center flex-col text-[22px]'>
                <img src={avatar} alt="User avatar" className='w-[160px] rounded-full object-cover' />
                <p className='capitalize text-[18px]'>{user?.profile?.first_name} {user?.profile?.last_name}</p>
                <p className='capitalize text-[18px]'>{user?.profile?.address?.about?.profession?.name}</p>
                <p className='capitalize text-[18px]'>{user && user?.profile?.address ? user?.profile?.address?.residential_state?.name + ', ' + user?.profile?.address?.residential_state?.country?.name : ""}</p>
            </div>

            {/* <div>
        <ProgressActive title="CV Rating" percent={70} />
        <ProgressActive title="Profile Rating" percent={40} />
        <ProgressActive title="LinkedIn Rating" percent={50} />
      </div> */}
        </div>
    )
}

export default BioDataEdit