import React from 'react'

// constants
import { FaEdit, FaSave } from 'react-icons/fa'

// types
import { ICountryStateUpdate } from '../../../store/types/misc'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { get_countries, get_states } from '../../../services/api/auth'

type IDetails = {
    onSubmit?: (data: ICountryStateUpdate) => void
    title: string
    description?: string
    className?: string
    editable?: boolean
    selectedCountry: string;
    selectedState: string;
}

const DetailEditStateCountrySelect: React.FC<IDetails> = ({ title, selectedState, selectedCountry, description, className, editable, onSubmit }) => {
  const dispatch = useAppDispatch();
  const countries = useAppSelector(({misc}) => misc.countries);
  const states = useAppSelector(({misc}) => misc.states)
  const [data, dataSet] = React.useState<ICountryStateUpdate>({
    country_id: '',
    state_id: ''
  });
  const [edit, editSet] = React.useState<boolean>(false)

  const onChangeCountry = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const {name, value} = e.target;
    dataSet(prev => ({...prev, [name]: value}));
    get_states(value, dispatch)
  };

  const onChangeState = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const {name, value} = e.target;
    dataSet(prev => ({...prev, [name]: value}));
  };

  const onLoad = React.useCallback(() => {
    get_countries(dispatch);
  }, [dispatch])

  const onGetState = React.useCallback(() => {
    get_states(selectedState, dispatch)
  }, [dispatch, selectedState]);

  React.useEffect(() => {
    onLoad();
    onGetState()
  }, [onGetState, onLoad])

  return (
    <div className={`w-full border-b border-[#4E4E4E] text-[#4E4E4E] text-[18px] flex py-3 ${className}`}>
      <div className='md:w-[30%] mr-2'>{title}</div>
      <div className='md:w-[70%] flex items-center justify-between pr-3'>
        <div className='w-[50%]'>
          {!edit ? description : null}

         {edit && editable && (
            <div className='flex'>
              <select onChange={onChangeCountry} defaultValue={selectedCountry} name="country_id" placeholder="Country" className='w-full border mx-1 outline-none focus:ring-0 rounded-lg px-1 capitalize'>
                {countries.map(option => <option value={option.id} key={option.id}>{option.name}</option>)}
              </select>
              <select onChange={onChangeState} defaultValue={selectedState} name="country_id" placeholder="Country" className='w-full border mx-1 outline-none focus:ring-0 rounded-lg px-1 capitalize'>
                {states.map(option => <option value={option.id} key={option.id}>{option.name}</option>)}
              </select>
            </div>
          )}
        </div>
        <div className=''>
          {editable && !edit ? 
            <FaEdit onClick={() => editSet(!edit)} className='ml-3 cursor-pointer' /> 
            : editable && edit ? 
            <FaSave 
              onClick={() => {
                if (Object.keys(data).length) {
                  onSubmit?.(data);
                }
                editSet(!edit)
              }} 
              className='ml-3 cursor-pointer' 
            /> 
            : null 
          }
        </div>
      </div>
    </div>
  )
}

export default DetailEditStateCountrySelect
