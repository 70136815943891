import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// auth and pages
import AuthRoute from "./auth";
import PrivateRoute from "./private";

// components
import {
  Login,
  Register,
  ForgotPassword,
  ChangePassword,
} from "../screens/auth/";
import ConsentFormPage from "../screens/auth/consent";

const MainNavigation: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/consent-form" element={<ConsentFormPage />} />
        <Route
          path="/*"
          element={
            <PrivateRoute>
              <AuthRoute />
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default MainNavigation;
