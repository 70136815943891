// components
import Detail from "../../../components/ui/cards/Detail";

// state
import { useAppSelector } from "../../../store/hooks";

const ProfileData = () => {
  const user = useAppSelector(({ auth }) => auth.user);
  return (
    <div className="px-5 py-7 bg-[white] font-abel">
      <Detail
        title="Full Name: "
        description={
          user ? user?.profile?.first_name + " " + user?.profile?.last_name : ""
        }
      />

      <Detail title="Email: " description={user ? user?.email : ""} />

      <Detail
        title="Phone Number: "
        description={user ? user?.profile?.phone : ""}
      />

      <Detail
        title="Occupation: "
        className="capitalize"
        description={
          user ? user?.profile?.address?.about?.profession?.name : ""
        }
      />

      <Detail
        title="Experience Level: "
        description={user ? user?.profile?.dob : ""}
      />

      <Detail
        title="Gender: "
        className="capitalize"
        description={user ? user?.profile?.gender_status?.name : ""}
      />

      <Detail
        title="Marriage: "
        className="capitalize"
        description={
          user && user?.profile?.address?.about
            ? user?.profile?.address?.about?.marital_status?.name
            : ""
        }
      />

      <Detail title="DOB: " description={user ? user?.profile?.dob : ""} />

      <Detail
        title="Address: "
        className="capitalize"
        description={
          user && user?.profile?.address ? user?.profile?.address?.address : ""
        }
      />

      <Detail
        title="Residential: "
        className="capitalize"
        description={
          user &&
          user?.profile?.address &&
          user?.profile?.address?.residential_state?.name
            ? user?.profile?.address?.residential_state?.name +
              " " +
              user?.profile?.address?.residential_state?.country?.name
            : ""
        }
      />

      <Detail
        title="Nationality & Origin: "
        className="capitalize"
        description={`${user?.profile?.address?.origin_state?.name || ""} ${
          user?.profile?.address?.origin_state?.country?.name || ""
        }`}
      />
    </div>
  );
};

export default ProfileData;
