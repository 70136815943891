// styles
import "./index.css";

// router
import MainNavigation from "./router";

// store
import { Provider } from "react-redux";
import { store } from "./store";

// environment
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import env from "./config/env";

if (env.enviroment === "production") {
  disableReactDevTools();
}

function App() {
  return (
    <Provider store={store}>
      <MainNavigation />
    </Provider>
  );
}

export default App;
