import React from 'react'

// component
import { Spin } from 'antd';

type SubmitButtonProps = {
    title: string
    onSubmitHandler: () => void | object
    loading?: boolean
    icon?: React.ReactNode
    className?: string
    disabled?: boolean
}

const SubmitButton: React.FC<SubmitButtonProps> = ({title, onSubmitHandler, loading, icon, className, disabled}) => {
  return (
    <button onClick={onSubmitHandler} disabled={loading || disabled} className={`text-lg font-abel py-2 px-4 bg-[#243677] text-[white] rounded-md hover:bg-[#CDD8FE] hover:text-[#243677] hover:border-[#CDD8FE] w-full border-[#243677] shadow-md border font-bold ${className}`}>
       {loading && <Spin />} {title}
    </button>
  )
}

export default SubmitButton