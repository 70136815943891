import React from 'react'

// router
import { Link } from 'react-router-dom'

// constants
// import crest from '../../assets/images/icons/white_logo.png'
import avatar from '../../assets/images/temporary/user.png'
import { BsBell } from 'react-icons/bs';
import { BiLogOut, BiSearchAlt } from 'react-icons/bi';

// state
import { get_full_profile, logout_user } from '../../services/api/auth'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import SearchInput from '../../components/elements/forms/SearchInput';
// import { setSearch } from '../../store/slices/navbar';

const Navbar: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(({ auth }) => auth.user)

  const onLoad = React.useCallback(() => {
    get_full_profile(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  const user_avatar = user?.profile && user?.profile?.avatar ? user?.profile?.avatar : avatar
  // const search = useAppSelector(({ navbar }) => navbar.search);

  return (
    <nav className='flex items-center justify-between px-5 py-2 shadow border-[#243677]'>
      {/* <div>
        <Link to="/">
          <img src={crest} alt="25th and Staffing Logo" className='h-[60px] w-[80px] cursor-pointer' />
        </Link>
      </div> */}
      <div className="w-[30%]">
				<SearchInput
					name="search"
					value=''
					placeholder="Search..."
					icon={<BiSearchAlt size={18} color="#243677" />}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						// const { value } = e.target;
						// dispatch(setSearch(value));
					}}
				/>
			</div>
      <div className='flex items-center'>
        <BsBell size={22} className='mr-4 text-[#CDD8FE]' />
        <div className='mr-3 hidden md:block'>
          <p className='capitalize text-[#243677] text-[14px]'>Welcome: {user?.profile?.first_name} .{user?.profile?.last_name?.charAt(0)}.</p>
        </div>
        <div className='items-center mx-3'>
          <div className='rounded-full'>
            <Link to="/profile">
              <img src={user_avatar} alt="User Avatar" className='h-[40px] w-[40px] rounded-full' />
            </Link>
          </div>
        </div>

        {/* <div className='mx-3'>
          <img src={notification} alt="Notification" className='md:h-[25px] h-[20px] w-20px md:w-[20px] cursor-pointer' />
        </div> */}

        <div className='mx-3 flex items-center cursor-pointer' onClick={() => logout_user?.(dispatch)}>
          <BiLogOut size={20} />
        </div>
      </div>
    </nav>
  )
}

export default Navbar
