import React from "react";

// components
import FullNameUpdate from "./edit/FullNameUpdate";
import UpdateAvatar from "./edit/UpdateAvatar";
import UpdateAddress from "./edit/UpdateAddress";
import UpdateAbout from "./edit/UpdateAbout";
import UpdateSocialMediaLinks from "./edit/UpdateSocialMediaLinks";
import UpdatePhoneNumber from "./edit/UpdatePhoneNumber";
import UpdateDateOfBirth from "./edit/UpdateDateOfBirth";
import WorkExperience from "./WorkExperience";
import UpdateGender from "./edit/UpdateGender";
import Education from "./Education";
import Skill from "./Skill";

// state
import { useAppDispatch } from "../../../store/hooks";
import { get_gender, get_marital_statuses } from "../../../services/api/misc";
import Certification from "./Certification";

const EditProfile = () => {
  const dispatch = useAppDispatch();

  const onLoad = React.useCallback(() => {
    get_gender(dispatch);
    get_marital_statuses(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="px-5 py-7 bg-[white] pt-4 mb-3">
      <div className="border-b flex  flex-wrap w-full">
        <div className="w-full md:w-[30%]">
          <p className="font-bold">About</p>
          <p className="text-[13px]">Tell us about yourself</p>
        </div>
        <div className="w-full md:w-[70%] mb-4">
          <FullNameUpdate />
          <UpdatePhoneNumber />
          <UpdateDateOfBirth />
          <UpdateAvatar />
          <UpdateAddress />
          <UpdateAbout />
        </div>
      </div>

      <div className="border-b flex flex-wrap w-full pt-4">
        <div className="w-full md:w-[30%]">
          <p className="font-bold">Your Skills</p>
          <p className="text-[13px]">Add relevant work skills</p>
        </div>
        <div className="w-full md:w-[70%]">
          <Skill />
        </div>
      </div>

      <div className="border-b flex flex-wrap w-full pt-4">
        <div className="w-full md:w-[30%]">
          <p className="font-bold">Work experience</p>
          <p className="text-[13px]">
            Add relevant work experience. Start with your most recent experience
          </p>
        </div>
        <div className="w-full md:w-[70%]">
          <WorkExperience />
        </div>
      </div>

      <div className="border-b flex flex-wrap w-full pt-4">
        <div className="w-full md:w-[30%]">
          <p className="font-bold">Education</p>
          <p className="text-[13px]">What school(s) did you attend?</p>
        </div>
        <div className="w-full md:w-[70%]">
          <Education />
        </div>
      </div>

      <div className="border-b flex flex-wrap w-full pt-4">
        <div className="w-full md:w-[30%]">
          <p className="font-bold">Certification</p>
          <p className="text-[13px]">
            It validates your competence and compliance through training, exams,
            and assessment, boosting career prospects by proving expertise and
            credibility to employers and clients. It also sets industry
            standards, promotes quality, and ensures accountability.
          </p>
        </div>
        <div className="w-full md:w-[70%]">
          <Certification />
        </div>
      </div>

      <div className="border-b flex flex-wrap w-full pt-4">
        <div className="w-full md:w-[30%]">
          <p className="font-bold">Social Profiles</p>
          <p className="text-[13px]">Where can we find you?</p>
        </div>
        <div className="w-full md:w-[70%] mb-4">
          <UpdateSocialMediaLinks />
        </div>
      </div>

      <div className="border-b flex flex-wrap w-full pt-4 pb-4">
        <div className="w-full md:w-[30%]">
          <p className="font-bold">Identity</p>
          <p className="text-[13px] mb-4">
            At 25th & Staffing, we’re committed to promoting inclusive hiring
            practices for companies. Self-identification is entirely voluntary,
            and your information will be treated with the utmost care. Rest
            assured, your Identity responses won't be visible on your profile.
          </p>
        </div>
        <div className="w-full md:w-[70%] mb-4">
          <UpdateGender />
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
