import React from "react";

// constants
import { RxCaretDown, RxCaretUp } from "react-icons/rx";

// types and state
import { ErrorWarning, CustomSelectItem } from "../../../store/types/misc";
import { Spin } from "antd";

type ICustomAutoComplete = {
  icon?: React.ReactNode;
  status?: ErrorWarning;
  container?: string;
  placeholder: string;
  items: CustomSelectItem[];
  value: string;
  loading?: boolean;
  onSelect: (res: CustomSelectItem) => void;
  onCreate: (res: string) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const CustomAutoComplete: React.FC<ICustomAutoComplete> = ({
  icon,
  container,
  value,
  status,
  loading,
  placeholder,
  items,
  onSelect,
  onCreate,
  onChange,
}) => {
  const [show, showSet] = React.useState<boolean>(false);
  const onFilterSearch = items.filter((item) =>
    item.name.includes(value?.toLowerCase())
  );

  return (
    <div className={`w-full relative text-[15px] font-abel ${container}`}>
      <div
        onClick={() => showSet(!show)}
        className={`cursor-pointer flex justify-between bg-[white] py-3 px-4 rounded-lg items-center border ${
          !status ? "border-[#243677]" : "border-[red]"
        }  h-[48px]`}
      >
        <div className="flex items-center w-full h-full">
          {icon}
          <input
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            className="w-full border-none h-full placeholder:font-abel ml-2 capitalize"
          />
        </div>
        {show ? (
          <RxCaretDown size={20} color="#243677" />
        ) : (
          <RxCaretUp size={20} color="#243677" />
        )}
      </div>
      {show && value && onFilterSearch.length > 0 && (
        <div
          className={`absolute capitalize max-h-[200px] py-3 px-4 top-[60px] text-[15px] z-50 overflow-hidden overflow-y-auto overflow-x-hidden rounded-lg left-0 w-full bg-[white] border hover:border-[#243677] border-[#243677]`}
        >
          {onFilterSearch?.map((item, index) => (
            <div
              key={item.id}
              onClick={() => {
                onSelect(item);
                showSet(false);
              }}
              className={`cursor-pointer my-2 ${
                onFilterSearch.length === index + 1
                  ? ""
                  : "border-b border-[#243677] py-[3px]"
              }`}
            >
              <div>{item?.name}</div>
              {item?.description && <div>{item?.description}</div>}
            </div>
          ))}
        </div>
      )}
      {show && value && onFilterSearch.length < 1 && (
        <div
          className={`absolute capitalize max-h-[200px] py-3 px-4 top-[60px] text-[15px] z-50 overflow-hidden overflow-y-auto overflow-x-hidden rounded-lg left-0 w-full bg-[white] border hover:border-[#243677] border-[#243677]`}
        >
          <div
            className="cursor-pointer my-2 py-[3px] flex justify-between items-center"
            onClick={() => {
              if (!loading) onCreate(value);
            }}
          >
            Create "{value}" {loading && <Spin />}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomAutoComplete;
