import React from 'react'

// constants
import { FaFacebookSquare, FaInstagramSquare, FaLinkedin, FaSkype, FaTwitterSquare, FaWhatsappSquare, FaGlobeAmericas } from "react-icons/fa";
import { useAppSelector } from '../../../store/hooks';

// react router
import { Link } from 'react-router-dom';

const SocialData = () => {
    const user = useAppSelector(({auth}) => auth.user);
  return (
    <div className='bg-[white] flex flex-wrap items-center justify-between md:my-5 py-3 px-3'>
        {user?.profile?.address?.about?.social?.facebook && 
          <Link to={user?.profile?.address?.about?.social?.facebook}>
            <FaFacebookSquare size={40} className='my-2 cursor-pointer' />
          </Link>
        }
        
        {user?.profile?.address?.about?.social?.instagram && 
          <Link to={user?.profile?.address?.about?.social?.instagram}>
            <FaInstagramSquare size={40} className='my-2 cursor-pointer' />
          </Link>
        }

        {user?.profile?.address?.about?.social?.linked_in && 
          <Link to={user?.profile?.address?.about?.social?.linked_in}>
            <FaLinkedin size={40} className='my-2 cursor-pointer' />
          </Link>
        }

        {user?.profile?.address?.about?.social?.skype && 
          <Link to={user?.profile?.address?.about?.social?.skype}>
            <FaSkype size={40} className='my-2 cursor-pointer' />
          </Link>
        }

        {user?.profile?.address?.about?.social?.twitter && 
          <Link to={user?.profile?.address?.about?.social?.twitter}>
            <FaTwitterSquare size={40} className='my-2 cursor-pointer' />
          </Link>
        }

        {user?.profile?.address?.about?.social?.whats_app && 
          <Link to={user?.profile?.address?.about?.social?.whats_app}>
            <FaWhatsappSquare size={40} className='my-2 cursor-pointer' />
          </Link>
        }

        {user?.profile?.address?.about?.social?.website && 
          <Link to={user?.profile?.address?.about?.social?.website}>
            <FaGlobeAmericas size={40} className='my-2 cursor-pointer' />
          </Link>
        }
    </div>
  )
}

export default SocialData