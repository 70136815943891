import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IDataType } from "../types/misc";

const initialState: {
  companies: IDataType[];
  loading: boolean;
  update: boolean;
} = {
  companies: [],
  loading: false,
  update: false,
};

export const company = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompany: (state, action: PayloadAction<IDataType[]>) => {
      state.companies = action.payload;
    },
    updateCompany: (state, action: PayloadAction<IDataType>) => {
      state.companies = [...state.companies, action.payload];
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
  },
});

export const { setCompany, updateCompany, setLoading } = company.actions;

export default company.reducer;
