import React from 'react'

type IButton = {
  title: string
  type: 'submit' | 'button'
  onSubmit: () => void
  backgroundColor?: string
  textColor?: string
  className?: string
}

const Button: React.FC<IButton> = ({title, type, backgroundColor, textColor, className, onSubmit}) => {
  return (
    <button
      type={type}
      className={`border border-[#ddd] rounded-md md:px-3 md:py-[3px] ${backgroundColor || 'bg-transparent'} ${textColor || 'text-[#243677]'} ${className}`} 
      onClick={onSubmit}>
        {title}
    </button>
  )
}

export default Button