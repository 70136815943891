import React from 'react'

// components
import Navbar from './header/Navbar';

type ThemeProps = {
    children?: React.ReactNode
}

const Theme: React.FC<ThemeProps> = ({children}) => {
  return (
    <section className='w-full font-abel'>
        <header className='w-full'>
            <Navbar />
        </header>
        <section>
            {children}
        </section>
        <footer></footer>
    </section>
  )
}

export default Theme