// import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";

// types and state
import { setDegree, updateDegree, setLoading } from "../../store/slices/degree";

export const get_degrees = async (dispatch: Function) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/get-degrees");

    dispatch(setDegree(response.data));
  } catch (error) {
    dispatch(setDegree([]));
  }
  dispatch(setLoading());
};

export const create_degree = async (
  dispatch: Function,
  data: { name: string }
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/create-degree", data);

    dispatch(updateDegree(response.data?.data));
  } catch (error) {
    // dispatch(setRole([]));
  }
  dispatch(setLoading());
};
