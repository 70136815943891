import React from "react";

// components
import DateInput from "../../../../components/elements/forms/DateInput";
import Button from "../../../../components/elements/forms/Button";

// state and services
import { ISingleProfileData } from "../../../../store/types/profile";
import { update_dob } from "../../../../services/api/profile";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";

const UpdateDateOfBirth = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(({ auth }) => auth.user);
  const [show, showSet] = React.useState<boolean>(false);
  const [data, dataSet] = React.useState<ISingleProfileData>({
    name: "",
  });

  const onLoad = React.useCallback(() => {
    if (user) {
      dataSet((prev) => ({
        ...prev,
        name: user?.profile?.dob || "",
      }));
    }
  }, [user]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="w-full">
      <div className="flex w-full mt-3 placeholder:text-[grey] ">
        <DateInput
          value={data.name || new Date().toString()}
          container="w-full"
          status=""
          placeholder="Date of Birth"
          onSelect={(res) => {
            showSet(true);
            dataSet((prev) => ({ ...prev, name: res }));
          }}
        />
      </div>

      {show && (
        <div className="w-full text-right my-2">
          <Button
            title="Save"
            type="button"
            onSubmit={() => {
              update_dob(dispatch, data);
              showSet(false);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default UpdateDateOfBirth;
