import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";

// types and state
import {
  setLoading,
  setExperiences,
  createExperience,
  updateExperiences,
} from "../../store/slices/experience";
import { IWorkExperienceData } from "../../store/types/auth";
import { openNotification } from "../../components/ui/alerts";

export const get_work_experience = async (dispatch: any) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/get-work-expereince");

    dispatch(setExperiences(response.data));
  } catch (error) {
    dispatch(setExperiences([]));
  }
  dispatch(setLoading());
};

export const create_experience = async (
  dispatch: Function,
  data: IWorkExperienceData
) => {
  let result;
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/save-work-expereince", data);

    dispatch(createExperience(response.data?.data));
    openNotification({ message: response?.data?.message, status: "success" });
    result = true;
  } catch (error) {
    if (isAxiosError(error)) {
      openNotification({
        message: error?.response?.data?.message,
        status: "error",
      });
      result = false;
    }
  }
  dispatch(setLoading());
  return result;
};

export const update_experience = async (
  dispatch: Function,
  data: IWorkExperienceData
) => {
  let result;
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/update-work-expereince", data);

    dispatch(updateExperiences(response?.data?.data));
    openNotification({ message: response?.data?.message, status: "success" });
    result = true;
  } catch (error) {
    if (isAxiosError(error)) {
      openNotification({
        message: error?.response?.data?.message,
        status: "error",
      });
      result = false;
    }
  }
  dispatch(setLoading());
  return result;
};
