import React from "react";
import useDashboard from "../hooks/useDashboard";

// components
import { Tabs } from "antd";

// constants
import { Link } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";

// state
import { get_full_profile } from "../../../services/api/auth";
import { get_education } from "../../../services/api/education";
import { get_work_experience } from "../../../services/api/experience";
import { useAppDispatch } from "../../../store/hooks";
import { get_courses } from "../../../services/api/course";
import { get_colleges } from "../../../services/api/college";
import { get_degrees } from "../../../services/api/degree";

const Profile = () => {
  const dispatch = useAppDispatch();
  const { items } = useDashboard();

  const onLoad = React.useCallback(() => {
    get_full_profile(dispatch);
    get_education(dispatch);
    get_work_experience(dispatch);
    get_courses(dispatch);
    get_degrees(dispatch);
    get_colleges(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="w-full md:bg-[#F5F5F5] min-h-screen md:flex md:justify-between font-abel px-5 py-3">
      <div className="md:max-w-[900px] mx-auto w-[100%] block bg-[white] md:px-2 md:py-3">
        <div className="mb-2">
          <Link to="/">
            <BsArrowLeft size={30} color="#243677" />
          </Link>
        </div>
        <Tabs defaultActiveKey="1" items={items} onChange={onLoad} />
      </div>
    </div>
  );
};

export default Profile;
