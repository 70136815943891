import React from "react";

// components
import TextInputBox from "../../../components/elements/forms/TextInputBox";
import DateInput from "../../../components/elements/forms/DateInput";
import CheckBox from "../../../components/elements/forms/CheckBox";
import Button from "../../../components/elements/forms/Button";
import CustomAutoComplete from "../../../components/elements/forms/CustomAutoComplete";

// state and types
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import {
  IWorkExperience,
  IWorkExperienceData,
  IWorkExperienceError,
} from "../../../store/types/auth";
import { create_role } from "../../../services/api/role";
import { create_company } from "../../../services/api/company";
import {
  create_experience,
  update_experience,
} from "../../../services/api/experience";
import { IDataType } from "../../../store/types/misc";
import { Spin } from "antd";

type IUpdateWorkExperience = {
  experience: IWorkExperience | null;
  onCancel: () => void;
};

const UpdateWorkExperience: React.FC<IUpdateWorkExperience> = ({
  experience,
  onCancel,
}) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(({ experience }) => experience.loading);
  const roles = useAppSelector(({ role }) => role.roles);
  const role_loading = useAppSelector(({ role }) => role.loading);
  const companies = useAppSelector(({ company }) => company.companies);
  const company_loading = useAppSelector(({ company }) => company.loading);
  const [fill, fillSet] = React.useState<{
    company: string;
    position: string;
  }>({
    company: "",
    position: "",
  });
  const [data, dataSet] = React.useState<IWorkExperienceData>({
    company_id: "",
    description: "",
    end_date: "",
    position_id: "",
    start_date: "",
    title_id: "",
    id: "",
    current: false,
  });
  const [error, errorSet] = React.useState<IWorkExperienceError>({
    company_id: "",
    description: "",
    end_date: "",
    position_id: "",
    start_date: "",
  });

  const onValidate = () => {
    let validate: boolean = false;
    if (!data.company_id) {
      validate = true;
      errorSet((prev) => ({ ...prev, company_id: "warning" }));
    }
    if (!data.start_date) {
      validate = true;
      errorSet((prev) => ({ ...prev, start_date: "warning" }));
    }
    if (!data.end_date && !data.current) {
      validate = true;
      errorSet((prev) => ({ ...prev, end_date: "warning" }));
    }
    if (!data.position_id) {
      validate = true;
      errorSet((prev) => ({ ...prev, position_id: "warning" }));
    }
    if (!data.description) {
      validate = true;
      errorSet((prev) => ({ ...prev, description: "warning" }));
    }
    if (validate) {
      return false;
    }
    return true;
  };

  const onSubmit = async () => {
    const validate = onValidate();
    if (validate && !data.id) {
      const response = await create_experience(dispatch, data);
      if (response) {
        onClear();
        onCancel();
      }
    } else if (validate && data.id.length) {
      const response = await update_experience(dispatch, data);
      if (response) {
        onClear();
        onCancel();
      }
    } else {
      return;
    }
  };

  const onClear = () => {
    dataSet({
      company_id: "",
      description: "",
      end_date: "",
      position_id: "",
      start_date: "",
      title_id: "",
      id: "",
      current: false,
    });
    fillSet({
      company: "",
      position: "",
    });
    errorSet({
      company_id: "",
      description: "",
      end_date: "",
      position_id: "",
      start_date: "",
    });
  };

  const onLoad = React.useCallback(() => {
    if (experience && experience.id) {
      dataSet((prev) => ({
        ...prev,
        company_id: experience?.company?.id?.toString(),
        description: experience?.description?.toString(),
        end_date: experience?.end_date,
        position_id: experience?.position?.id?.toString(),
        start_date: experience?.start_date,
        title_id: experience?.position?.id?.toString(),
        id: experience?.id?.toString(),
        current: experience?.end_date ? false : true,
      }));
      fillSet((prev) => ({
        ...prev,
        company: experience?.company?.name || "",
        position: experience?.position?.name || "",
      }));
    }
  }, [experience]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="py-0 mb-3 font-abel">
      <CustomAutoComplete
        items={companies}
        container="my-2"
        placeholder="Enter Company"
        value={fill.company}
        status={error.company_id}
        loading={company_loading}
        onCreate={(res) => create_company(dispatch, { name: res })}
        onChange={(e) =>
          fillSet((prev) => ({ ...prev, company: e.target.value }))
        }
        onSelect={(res: IDataType) => {
          fillSet((prev) => ({ ...prev, company: res.name }));
          errorSet((prev) => ({ ...prev, company_id: "" }));
          dataSet((prev) => ({ ...prev, company_id: res.id?.toString() }));
        }}
      />

      <CustomAutoComplete
        items={roles}
        container="my-2"
        placeholder="Enter Role Name"
        value={fill.position}
        onCreate={(res) => create_role(dispatch, { name: res })}
        loading={role_loading}
        status={error.position_id}
        onChange={(e) =>
          fillSet((prev) => ({ ...prev, position: e.target.value }))
        }
        onSelect={(res: IDataType) => {
          errorSet((prev) => ({ ...prev, position_id: "" }));
          fillSet((prev) => ({ ...prev, position: res.name }));
          dataSet((prev) => ({
            ...prev,
            position_id: res.id?.toString(),
            title_id: res.id?.toString(),
          }));
        }}
      />

      <div className="flex justify-between my-2 w-full">
        <DateInput
          placeholder="Start Date"
          container="w-[49%]"
          status={error.start_date}
          value={new Date().toString()}
          onSelect={(e) => {
            dataSet((prev) => ({ ...prev, start_date: e }));
            errorSet((prev) => ({ ...prev, start_date: "" }));
          }}
        />
        <DateInput
          placeholder="End Date"
          container="w-[49%]"
          disabled={data.current || false}
          status={error.end_date}
          value={new Date().toString()}
          onSelect={(e) => {
            dataSet((prev) => ({ ...prev, end_date: e }));
            errorSet((prev) => ({ ...prev, end_date: "" }));
          }}
        />
      </div>

      <CheckBox
        name="currently"
        id="currently"
        checked={data.current}
        onChange={() => {
          dataSet((prev) => ({ ...prev, current: !data.current }));
          if (!data.current) {
            dataSet((prev) => ({ ...prev, end_date: "" }));
            errorSet((prev) => ({ ...prev, end_date: "" }));
          }
        }}
        title="I currently work here"
      />

      <TextInputBox
        name="description"
        value={data.description}
        container="my-2"
        status={error.description}
        placeholder="Description"
        onChange={(e) => {
          const { name, value } = e.target;
          dataSet((prev) => ({ ...prev, [name]: value }));
          errorSet((prev) => ({ ...prev, [name]: "" }));
        }}
      />

      <div className="text-[#243677]">
        {loading ? (
          <Spin className="mx-5" />
        ) : (
          <Button
            title="Save"
            className="mr-5 text-[white] bg-[#243677] border border-[#243677]"
            type="button"
            onSubmit={onSubmit}
          />
        )}

        <Button title="Cancel" type="button" onSubmit={onCancel} />
      </div>
    </div>
  );
};

export default UpdateWorkExperience;
