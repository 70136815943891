import { notification } from "antd";
import { isAxiosError } from "axios";
import React from "react";
import { get_profile } from "../../services/api/auth";
import { useAppDispatch } from "../../store/hooks";
import axiosInstance from "../../utility/axiosInstance";
import axiosUpload from "../../utility/axiosUpload";

// component

// state

type IPHOTO = React.ChangeEvent<HTMLInputElement>

const useChangeAvatar = () => {
  const dispatch = useAppDispatch();
  const [upload_loading, uploadLoadingSet] = React.useState<boolean>(false)

  const handleAvatar = async (e: IPHOTO) => {
    uploadLoadingSet(true)
    const {files} = e.target;
    try {
      const body = new FormData();
      files && body.append('name', files?.[0]);
      
      const result = await axiosUpload.post('/image-upload', body);

      if (result.data) {
        const response = await axiosInstance.post('/update-avatar', {name: result.data});
        notification.success({
          message: "succesful",
          description: response.data?.message
        })
        get_profile(dispatch)
      }
    } catch (err) {
      if (isAxiosError(err)) {
        notification.error({
          message: "Failed Upload",
          description: err?.response?.data.message
        });
      }
    }
    uploadLoadingSet(false)
  };
  return { handleAvatar, upload_loading };
};

export default useChangeAvatar;