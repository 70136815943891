import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IAppicantState } from '../types/applicants';

// Define the initial state using that type
const initialState: IAppicantState = {
	total_applied: "",
	loading: false
};

export const applicants = createSlice({
	name: 'applicants',
	initialState,
	reducers: {
		setTotalAppliant: (state, action: PayloadAction<string>) => {
			state.total_applied = action.payload
		},
		setLoading: (state) => {
			state.loading = !state.loading
		}
	}
});

export const { setTotalAppliant, setLoading } = applicants.actions;

export default applicants.reducer;
