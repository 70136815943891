import React from 'react'

type IDetails = {
  title: string
  description: string
  className?: string
}

const Detail: React.FC<IDetails> = ({ title, description, className }) => {
  return (
    <div className={`flex items-center w-full text-[#4E4E4E] md:text-[16px] text-[14px] pb-3 font-abel ${className}`}>
      <div className=' md:w-[110px] mr-2 text-[14px]'>{title}</div>
      <div className='flex flex-row bg-[#f5f5f5] px-2 py-1 min-h-0 min-w-0 h-[33.14px]'>
        <div className=''><span>{description}</span></div>
      </div>
    </div>
  )
}

export default Detail