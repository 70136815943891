import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";

// types and state
import {
  setLoading,
  setEducation,
  createEducation,
  updateEducation,
} from "../../store/slices/education";
import { openNotification } from "../../components/ui/alerts";

export const get_education = async (dispatch: Function) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/get-education");

    dispatch(setEducation(response.data));
  } catch (error) {
    dispatch(setEducation([]));
  }
  dispatch(setLoading());
};

export const create_education = async (dispatch: Function, data: any) => {
  let result;
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/save-education", data);

    dispatch(createEducation(response.data?.data));
    openNotification({ message: response?.data?.message, status: "success" });
    result = true;
  } catch (error) {
    if (isAxiosError(error)) {
      openNotification({
        message: error?.response?.data?.message,
        status: "error",
      });
    }
    result = false;
  }
  dispatch(setLoading());
  return result;
};

export const update_education = async (dispatch: Function, data: any) => {
  let result;
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/update-education", data);

    dispatch(updateEducation(response.data?.data));
    openNotification({ message: response?.data?.message, status: "success" });
    result = false;
  } catch (error) {
    if (isAxiosError(error)) {
      openNotification({
        message: error?.response?.data?.message,
        status: "error",
      });
    }
    result = false;
  }
  dispatch(setLoading());
  return result;
};
