import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IDataType } from "../types/misc";

const initialState: {
  certificates: IDataType[];
  loading: boolean;
  update: boolean;
} = {
  certificates: [],
  loading: false,
  update: false,
};

export const certificate = createSlice({
  name: "certificate",
  initialState,
  reducers: {
    setCertificate: (state, action: PayloadAction<IDataType[]>) => {
      state.certificates = action.payload;
    },
    createCertification: (state, action: PayloadAction<IDataType>) => {
      state.certificates = [...state.certificates, action.payload];
    },
    updateCertificate: (state, action: PayloadAction<IDataType>) => {
      state.certificates = state.certificates.map((item) => {
        if (item.id?.toString() === action.payload.id?.toString())
          return action.payload;
        return item;
      });
    },
    deleteCertificate: (state, action: PayloadAction<IDataType>) => {
      state.certificates = state.certificates.filter(
        (item) => item.id?.toString() !== action.payload.id?.toString()
      );
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
  },
});

export const {
  setCertificate,
  createCertification,
  updateCertificate,
  deleteCertificate,
  setLoading,
} = certificate.actions;

export default certificate.reducer;
