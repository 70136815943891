import React from 'react';

type ITextInput = {
    name: string;
    placeholder: string;
    value: string | number | [];
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    icon?: React.ReactNode;
};

const SearchInput: React.FC<ITextInput> = ({
    placeholder,
    name,
    icon,
    value,
    onChange
}) => {
    return (
        <div className="w-full flex border rounded-sm p-[4px] bg-[white] border-[#e5e7eb] hover:border-[#243677]">
            {icon && <div className="py-2 px-3">{icon}</div>}
            <input
                name={name}
                id={name}
                type="text"
                onChange={onChange}
                value={value}
                placeholder={placeholder}
                className="px-2 bg-[white] outline-none flex-1 border-[#e5e7eb]"
            />
        </div>
    );
};

export default SearchInput;
