import React from "react";

// components
import { DatePicker } from "antd";
import type { DatePickerProps } from "antd";

// types
import { ErrorWarning } from "../../../store/types/misc";
import dayjs from "dayjs";

type IDateInput = {
  title?: string;
  onSelect: (dateString: string) => void;
  required?: boolean;
  container?: string;
  error?: ErrorWarning;
  status: ErrorWarning;
  placeholder?: string;
  value?: Date | string;
  disabled?: boolean;
};

const DateInput: React.FC<IDateInput> = ({
  title,
  placeholder,
  status,
  disabled,
  container,
  value = new Date(),
  required,
  onSelect,
}) => {
  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    onSelect?.(dateString);
    date?.format();
  };

  const borderColor = status ? "border-[red]" : "";

  return (
    <div className={`font-abel ${container}`}>
      <label className="block text-[#303030]">
        {title} {required && <span className="text-[red]">*</span>}
      </label>
      <DatePicker
        disabled={disabled || false}
        placeholder={placeholder}
        defaultValue={dayjs(value, "YYYY-MM-DD")}
        onChange={onChange}
        className={`w-full text-xl py-3 px-4 font-abel hover:border-[#243677] ${borderColor}`}
      />
    </div>
  );
};

export default DateInput;
