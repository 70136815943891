// components
import { TabsProps } from "antd";
import TabHeader from "../../../components/ui/tab_header";
import ProfileData from "../misc/ProfileData";
import EditProfile from "../misc/EditProfile";
import ResumeCv from "../misc/ResumeCv";

// constants
import { GrOverview, GrUser, GrDocumentPdf } from "react-icons/gr";

const useDashboard = () => {
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <TabHeader title="Overview" icon={<GrOverview color="#243677" />} />
      ),
      children: <ProfileData />,
    },
    {
      key: "2",
      label: (
        <TabHeader title="Edit Profile" icon={<GrUser color="#243677" />} />
      ),
      children: <EditProfile />,
    },
    {
      key: "3",
      label: (
        <TabHeader
          title="Resume / CV"
          icon={<GrDocumentPdf color="#243677" />}
        />
      ),
      children: <ResumeCv />,
    },
  ];

  return { items };
};

export default useDashboard;
