// icons
import icon_white from '../assets/images/icons/crest_white.png'
import twitter from '../assets/images/icons/twitter.svg'

const db =  {
    icon_black: "https://res.cloudinary.com/defwvlcup/image/upload/v1674636928/25thandstaffing/icon_black_yvmoug.png",
    icon_white: icon_white,
    twitter: twitter,
}

export default db;