import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IEducation } from "../types/auth";

// Define the initial state using that type
const initialState: {
  educations: IEducation[];
  loading: boolean;
  update: boolean;
} = {
  educations: [],
  loading: false,
  update: false,
};

export const education = createSlice({
  name: "education",
  initialState,
  reducers: {
    setEducation: (state, action: PayloadAction<IEducation[]>) => {
      state.educations = action.payload;
    },
    createEducation: (state, action: PayloadAction<IEducation>) => {
      state.educations = [action.payload, ...state.educations];
    },
    updateEducation: (state, action: PayloadAction<IEducation>) => {
      state.educations = state.educations.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload;
        }
        return item;
      });
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setUpdate: (state) => {
      state.update = !state.update;
    },
  },
});

export const { setEducation, createEducation, updateEducation, setLoading } =
  education.actions;

export default education.reducer;
