import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IDataType } from "../types/misc";

const initialState: {
  degrees: IDataType[];
  loading: boolean;
} = {
  degrees: [],
  loading: false,
};

export const degree = createSlice({
  name: "degree",
  initialState,
  reducers: {
    setDegree: (state, action: PayloadAction<IDataType[]>) => {
      state.degrees = action.payload;
    },
    updateDegree: (state, action: PayloadAction<IDataType>) => {
      state.degrees = [...state.degrees, action.payload];
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
  },
});

export const { setDegree, updateDegree, setLoading } = degree.actions;

export default degree.reducer;
