import React from 'react'

// router
import { Link } from 'react-router-dom'

type IButtonInterface = {
    title: string
    path: string
    className?: string
}

const LinkButton: React.FC<IButtonInterface> = ({ title, path, className }) => {
    return (
        <Link to={path} preventScrollReset={true} className={`text-center text-lg font-abel py-2 px-4 bg-[#243677] text-[white] rounded-md hover:bg-[#CDD8FE] hover:text-[#243677] hover:border-[#CDD8FE] w-full border-[#243677] shadow-md border font-bold ${className}`}>
            <span className={``}>
                {title}
            </span>
        </Link>
    )
}

export default LinkButton