import React from "react";
import useChangePassword from "../hooks/useChangePassword";

// components
import TextInput from "../../../components/elements/forms/TextInput";

// theme
import Landing from "../app";

// assets
import SubmitButton from "../../../components/elements/forms/SubmitButton";
import { Link, useNavigate } from "react-router-dom";

// types and state
import { useAppSelector } from "../../../store/hooks";
import OTPInput from "../../../components/elements/forms/OTPInput";

const ChangePassword: React.FC = () => {
  const navigate = useNavigate();
  const loading = useAppSelector(({ loading }) => loading?.loading);
  const { err, onChange, data, dataSet, visible, onValidateToken, onValidate } =
    useChangePassword();

  const onLoad = React.useCallback(() => {
    const email = localStorage.getItem("@email");
    if (!email) {
      navigate("/forgot-password");
    } else {
      dataSet((prev) => ({ ...prev, email }));
    }
  }, [dataSet, navigate]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <Landing>
      <div className="md:flex font-abel md:py-10 md:flex-row bg-[#EFF2FE] md:justify-center md:items-center">
        <div className="bg-[white] px-4 py-10 items-center md:mx-5 max-w-[695px] border-none rounded-lg md:px-12 md:pt-12 md:pb-16 md:my-12">
          {!visible ? (
            <>
              <div className="text-lg font-[700] text-[#000000] text-opacity-60 mb-5">
                <h3 className="md:text-[23px] text-[18px] font-[900]">
                  {data.email}
                </h3>
              </div>

              <div className="flex flex-col justify-start">
                <p className="text-md text-left text-[#aaaaaa]">
                  An Otp has been sent to your email.
                </p>
                <OTPInput
                  value={data.token}
                  handleChange={(res) =>
                    dataSet((prev) => ({ ...prev, token: res }))
                  }
                />
              </div>
            </>
          ) : (
            <>
              <div className="text-left w-full my-5">
                <h3 className="md:text-[30px] text-[23px] font-[900]">
                  Update Password
                </h3>
                {/* <span className='text-lg font-[700] text-[#aaaaaa]'>Token  has been sent to your email.</span> */}
                {/* <span className="text-lg font-[700] text-[#aaaaaa]">
                  Please Enter new Password.
                </span> */}
              </div>

              <p className="text-lg font-[700] text-[#000000] text-opacity-60">
                {data.email}
              </p>

              <TextInput
                type="password"
                placeholder="New Password"
                container="my-3"
                status={err.password}
                name="password"
                value={data.password}
                onChange={onChange}
              />

              <TextInput
                type="password"
                placeholder="Confirm New Password"
                container="my-3"
                status={err.password_confirmation}
                name="password_confirmation"
                value={data.password_confirmation}
                onChange={onChange}
              />
            </>
          )}

          <div className="w-[100%] flex items-center mt-7 align-center">
            <SubmitButton
              loading={loading}
              title={!visible ? "Continue" : "SIGN IN"}
              onSubmitHandler={() => {
                if (!visible) {
                  onValidateToken();
                } else {
                  onValidate();
                }
              }}
              className="bg-[#243677] font-[400] text-white md:hover:bg-[white] md:hover:text-[#243677] w-[30%]"
            />
          </div>

          <div className="text-center mt-6">
            <span>
              Don&apos;t have an account?{" "}
              <Link to="/login" className="text-[#243677]">
                {" "}
                Sign In
              </Link>
            </span>
          </div>
        </div>
      </div>
    </Landing>
  );
};

export default ChangePassword;
