import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";

// types and state
import { setLoading, setTotalAppliant } from "../../store/slices/applicants";

type IGetTotalApplied = (dispatch: any) => Promise<void>;
export const get_total_applied: IGetTotalApplied = async (dispatch: any) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/total-applied-jobs");

    dispatch(setTotalAppliant(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
  dispatch(setLoading());
};
