import React from 'react'

// types and state
import { get_countries, get_states } from '../../../../services/api/auth';
import { update_address } from '../../../../services/api/profile';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { IUpdateAddress } from '../../../../store/types/profile';

// components
import TextInput from '../../../../components/elements/forms/TextInput';
// import SelectInput from '../../../../components/elements/forms/SelectInput';
import Button from '../../../../components/elements/forms/Button';
import CustomSelect from '../../../../components/elements/forms/CustomSelect';

const UpdateAddress = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(({ auth }) => auth.user);
  const countries = useAppSelector(({ misc }) => misc.countries)
  const states = useAppSelector(({ misc }) => misc.states)
  const [selectedState, selectedStateSet] = React.useState<string>('')
  const [data, dataSet] = React.useState<IUpdateAddress>({
    address: '',
    state_id: '',
    country_id: ''
  })

  const onLoad = React.useCallback(() => {
    if (user && user?.profile?.address) {
      dataSet(prev => ({
        ...prev,
        address: user?.profile?.address?.address,
        state_id: user?.profile?.address?.residential_state?.id || "",
        country_id: user?.profile?.address?.residential_state?.country?.id || ""
      }))
      selectedStateSet(user?.profile?.address?.origin_state?.id || "")
    }
  }, [user])

  const onGetRegion = React.useCallback(() => {
    get_countries(dispatch)
  }, [dispatch])

  React.useEffect(() => {
    onLoad()
    onGetRegion();
  }, [onGetRegion, onLoad])


  const state = user?.profile?.address?.residential_state?.name || ''
  const country = user?.profile?.address?.residential_state?.country?.name || ''

  return (
    <div className=''>
      <div>
        <TextInput
          name="address"
          placeholder='Address'
          value={data.address}
          onChange={e => {
            const { name, value } = e.target
            dataSet(prev => ({ ...prev, [name]: value?.toString() }))
          }}
        />
      </div>
      <div className='flex justify-between my-3'>
        <div className='w-[49%]'>
          <CustomSelect
            items={countries}
            placeholder={country || "Select Country"}
            selected={data.country_id}
            name='country_id'
            onSelect={(name, value) => {
              get_states(value, dispatch)
              dataSet(prev => ({ ...prev, [name]: value }))
            }}
          />
        </div>

        <div className='w-[49%]'>
          <CustomSelect
            items={states}
            placeholder={state || "Select State"}
            selected={data.state_id}
            name='state_id'
            onSelect={(name, value) => dataSet(prev => ({ ...prev, [name]: value }))}
          />
        </div>
      </div>

      {user && (selectedState !== data.state_id || user?.profile?.address?.address !== data.address)
        ?
        <div className='mb-3 text-right'>
          <Button title='Submit' type="button" onSubmit={() => update_address(dispatch, data)} />
        </div>
        : null
      }

    </div>
  )
}

export default UpdateAddress